const cn = {
  lang: {
    vietnam: "ベトナム語",
    english: "英語",
    japannese: "日本語",
  },
  seo: {
    home: {
      title: "ホームページ | TGL Solutions - Smart & Speed Solution",
      description:
        "最も快適な職場環境の作成、従業員が自分の可能性、個人の強み、およびキャリア志向を最大限に促進するため弊社は「人間中心主義」のもとで、会社のすべてのポリシー及び決定は、決まります。",
      keywords:
        "TGL Solutions, ソフトウェア会社, ソフトウェアソリューション, 先端技術, Webアプリケーション, モバイルアプリケーション, AI, メタバース, AutoCAD, Webサイト, アプリケーション, モバイル",
    },
    about_us: {
      title: "会社概要 | TGL Solutions - Smart & Speed Solution",
      description: "ベトナム人の価値を上げよう！",
    },
    why_us: {
      title: "人間関係 | TGL Solutions - Smart & Speed Solution",
      description: "TGL Solutions会社の「ファーストペンギン」の話",
    },
    product: {
      title: "製品 | TGL Solutions - Smart & Speed Solution",
      description:
        "TGL Solutions会社は、お客様の要件を満たし、品質確保しながら最速の時間でプロジェクトを完了します。 そのため、常連客一覧は増え続けています。",
    },
    contact: {
      title: "お問い合わせ | TGL Solutions - Smart & Speed Solution",
      description: "ファーストペンギンになりましょう。",
    },
    recruiment: {
      title: "求人 | TGL Solutions - Smart & Speed Solution",
      description: "TGL Solutionsへようこそ！",
    },
  },
  header: {
    home: "ホームページ",
    about_us: "会社概要",
    why_us: "人材",
    services: "サービス",
    projects: "開発実績",
    projectsOther: "他のプロジェクト",
    activities: "活動",
    contact: "お問い合わせ",
    recruiment: "採用情報",
    product: "製品",
    blog: "ブログ",
    selectLang: "言語",
  },
  decoration_house: {
    title: "住宅設計にAIシステムを活用",
    subtitle: "AIシステムを使って生活空間を創造的に設計します。",
    optionDecoration: "オプションを選択",
    labelUpload: "画像をアップロード",
    labelSample: "サンプルを選択",
    exterior: "外装",
    interior: "内装",
    theme: "テーマを選択",
    number: "画像出力数を選択",
    btnAi: "画像作成",
    note: "注: 画像の数が多ければ多いほど、処理速度が遅くなります。",
    label: "デザイン編集",
    result: "AIにより作成した画像",
    contentChoosePic: "または",
    queueBefore: "現在は",
    queueAfter: "少々お待ちください。",
    labelCountdown: "結果がそろそろ表示される",
    inProgress: "システムは画像を処理しています。少々お待ちください。",
    back: "戻る",
    upload: "画像アップロード",
    close: "閉じる",
    download: "ダウンロード",
    zoom: "ズーム",
    origin: "元画像",
    output: "現画像",
    reset: "新規作成",
    step1: "ステップ 1. ",
    step2: "ステップ 2. ",
    step3: "ステップ 3. ",
    step4: "ステップ 4. ",
    ex: "サンプル: ",
    drag: "体験しましょう！",
  },
  decoration_house_4season: {
    title: "設計図に基づいて、家の外観デザインが行われます。",
    subtitle:
      "四季折々の景色を織り交ぜ、お客様のご希望に合わせて独創的なスタイルでデザインされます。",
    optionDecoration: "オプションを選択",
    labelUpload: "画像をアップロード",
    labelSample: "サンプルを選択",
    exterior: "外装",
    interior: "内装",
    theme: "テーマを選択",
    number: "画像出力数を選択",
    btnAi: "画像作成",
    note: "注: 画像の数が多ければ多いほど、処理速度が遅くなります。",
    label: "デザイン編集",
    result: "AIにより作成した画像",
    contentChoosePic: "または",
    queueBefore: "現在は",
    queueAfter: "少々お待ちください。",
    labelCountdown: "結果がそろそろ表示される",
    inProgress: "システムは画像を処理しています。少々お待ちください。",
    back: "戻る",
    upload: "画像アップロード",
    close: "閉じる",
    download: "ダウンロード",
    zoom: "ズーム",
    origin: "元画像",
    output: "現画像",
    reset: "新規作成",
    step1: "ステップ 1. ",
    step2: "ステップ 2. ",
    step3: "ステップ 3. ",
    step4: "ステップ 4. ",
    drag: "体験しましょう！",
    ex: "サンプル: ",
    spring: "春",
    summer: "夏",
    autumn: "秋",
    winter: "冬",
    numFloor: "階数",
    errInput: "無効な値",
  },
  video: {
    cad: {
      title: "AutoCADプロジェクト",
      sub: "独自コマンドを開発し、パイピング作業を効率化するデモです。",
    },
    revit: {
      title: "REVITプロジェクト",
      sub: "自動配筋するデモです。設計業務を大幅に改善できます。",
    },
    aiHouse: {
      title: "AIが設計するあなたの夢のマイホームとは",
      sub: "AIが設計する住宅はこれまでとどんなふうに違うのか、きっとますます夢が膨らむはずです！好みのワードをチェックするだけ、感性をもとにAIが施工例を瞬時で提案します。",
      button: "無料で体験しましょう！",
    },
  },
  button: {
    show: "続きを読む",
    hide: "折りたたむ",
    seeMore: "詳細を見る",
  },
  ceo: {
    name: "CEO Le Tien Trien",
    title_image_1: "CEO Le Tien Trien",
    title_image_2: "2018年3月、日本で博士号を取得",
    ceo_content_1:
      "TGL Solutionsは、2019年9月にLe Tien Trien博士によって設立されました。ベトナム人の精神を取り入れ、TGL Solutionsを通じて社会の発展に貢献したいと考えている Le Tien Trien氏。そのため、国と社会のために、最もスマートで最速のソリューションを作成するという意味を込めて、Smart & Speed Solutions は弊社のスローガンです。",
    ceo_content_2:
      "今日のインダストリー 4.0の中で、なぜスマートとスピードが重要な要素のでしょうか。",
    ceo_content_3:
      "ITの時代で成長するのは何より幸運だと思います。この黄金時代は、インターネットの誕生（1995年）に始まり、通常50年続くことを示されています。いわゆる、私たちはこの黄金時代の真ん中に近づいているということです。",
    ceo_content_4:
      "2010年代初頭、人工知能（AI）の大きな発展により、以前はSF映画にしか登場しないと思われていた技術が人間にもたらされました。AIは、画像を高精度で分類および認識し、迅速な判断を行うことができるようになりました。AIはスマートフォンでより良い写真を撮ったり、発送注文をより迅速に処理したりする私たちの日常生活のあらゆる領域に忍び込みます。",
    ceo_content_5:
      "TGL Solutionsの「Smart & Speed Solutions」というスローガンは「Smart」と「Speed」は別の意味です。\nクラウドをもってAIは普及になり、簡単に使用できるようになるので、作業のパフォーマンスが向上できます。それで、AIが駆使できるように、「Smart」は人間にスマートの働き方を求める言葉です。",
    ceo_content_6:
      " 「Speed」については、現在の社会において、コンピュータサイエンスが発展しています。\nスマホを所有すれば、約25年前のスーパーコンピューターを簡単に所有したようです。\nその背景に対して、迅速に行動し、迅速に失敗し、迅速に改善しないと、確かに技術の発展に追いかけません。\n「Speed」は対策提出のスピードだけではなく、判断、修正のスピードです。それで、失敗が大した問題ではなく、成功の方法です。",
    title_content_1: "CEO Le Tien Trien の功績",
    array_achievements: [
      {
        id: 1,
        description: "全国優秀学生数学二等賞、大学進学",
        year: "2005年",
      },
      {
        id: 2,
        description: "日本政府の奨学金（文部科学省）を受けて日本に留学",
        year: "2007年",
      },
      {
        id: 3,
        description:
          "国費留学生奨学金支給期間の延長1回、特別更新2回、日本政府の奨学金（文部科学省）を通算11年連続受取",
        year: "2007年 - 2018年",
      },
      {
        id: 4,
        description: "日本で電気通信および情報技術の博士号を取得",
        year: "2018年",
      },
      {
        id: 5,
        description: "日立の研究所主任研究員である",
        year: "2018年 - 2021年",
      },
      {
        id: 6,
        description:
          "大学およびHITACHI集団での優れた研究業績により、数々の賞を受賞",
        year: "2013年 - 2021年",
      },
      {
        id: 7,
        description: "公開された特許を10件以上取得",
        year: "2017年 - 2021年",
      },
      {
        id: 8,
        description:
          "ドイツ（2013年）、カナダ（2014年）、中国（2014年、2015年、2016年）、フランス（2017年）、アメリカ（2019年）など、世界中の多くの国で開催された会議に出席し、著名な国際ジャーナルに発表された多くの科学論文を投稿 ",
        year: "2014年 - 2019年",
      },
    ],
    title_content_2: "CEO Le Tien Trien のキャリアにおけるマイルストーン",
    img_timeline: "/images/timeline-ceo.png",
    img_timeline_mobile: "/images/timeline-ceo-mobile.png",
    milestones_content: [
      "株式会社skyACE（東京本社）設立",
      "TGL Solutions会社（ホーチミン支社）設立",
      "Prima Solutions会社（ダナン支社）設立,",
    ],
  },

  home: {
    banner: {
      subtitleStart: "を方針に",
      subtitleBold: "『人間中心主義での会社設立』。",
      subtitleEnd:
        "最も快適な職場環境の作成、従業員が自分の可能性、個人の強み、およびキャリア志向を最大限に促進するため、会社のすべてのポリシー及び決定は、「人間中心主義」のもとで決まります。",
      btnContact: "お問い合わせ",
    },
    process: {
      title: "作業工程",
      cards: [
        {
          step: "要件定義",
          content: [
            "お客様からの依頼を受け入れ",
            "要件分析",
            "要件定義、インターフェースデザイン",
          ],
        },
        {
          step: "基本設計・詳細設計",
          content: ["リソースの分析及び特定", "作業を分割する計画を立て"],
        },
        {
          step: "製造・実装",
          content: [
            "詳細設計",
            "コーディング",
            "単体テスト",
            "プロジェクトリーダーがソースコードをチェックを行い",
          ],
        },
        {
          step: "動作確認・品質保証",
          content: [
            "QCチームは、モジュール テスト、システムテスト、ユーザー受け入れテストの3つの段階で製品テストを実行",
            "開発者はバグを修正し、製品を完成",
          ],
        },
        {
          step: "運用サポート・システム保守",
          content: ["完成後、お客様に納品", "稼働・メンテナンスをサポート"],
        },
      ],
    },
    service: {
      title: "サービス",
      sub: "TGL Solutionsは設立以来、ソフトウェア開発サービスの拡充と多様化を中心にして、マーケットのニーズや開発動向に応える努力を常に続けていきます。",
      cards: [
        {
          category: "Webシステム",
          title: "ウェブサイトシステム開発",
          content:
            "ウェブサイトのデザイン、プログラミング、最適化に関する包括的なソリューションを提供します。特に、VR/ライブ配信/オークション機能をECサイトに直接統合して、顧客とのやり取りまたは商取引の効率を上げることができます",
        },
        {
          category: "メタバース",
          title: "Metaverse開発",
          content:
            "NVIDIA オムニバース、アクターコアなどの新技術を応用し、操作方法を擬し、製品または生産プロセスのリスクを予測することで、インストール・製造時間の大部分を削減します。",
        },
        {
          category: "AI",
          title: "AI開発",
          content:
            "DeepLabv3、HRnetなどの高度なAI深層学習技術の応用は、病気の早期診断に期待されるように医学に貢献します。 それにより、患者の寿命を延ばし、優れた治療結果をもたらす治療法を提供しています。",
        },
        {
          category: "スマホアプリ",
          title: "スマホアプリ開発",
          content:
            "iOSおよびAndroidプラットフォーム向けのスマートフォンアプリ開発における長年の経験を基に、eコマースアプリ、ソーシャル ネットワーク、ビジネス管理アプリなど、さまざまな種類のアプリケーションを顧客向けに構築しています。",
        },
        {
          category: "PCアプリケーション",
          title: "PCアプリ開発",
          content:
            "TGL Solutionsの特徴は、客様の承認を得るためのデモを迅速に作成することです。これにより、お客様が求める品質要件に基づいたアプリケーション開発のソリューションを継続的に提供できるようになりました。",
        },
        {
          category: "BIM・CAD",
          title: "BIM/CAD開発",
          content:
            "C・C#言語を使用して、土木、建築、および機械設計図面でコマンドを作成します。生産性または効率を最適化するために、クライアントからの依頼に応じてCAD図面機能カスタマイズするを行います。",
        },
        {
          category: "All",
          title: "開発実績",
          content:
            "TGL Solutionsは、お客様の要件を満たし、品質確保しながら最速の時間でプロジェクトを完了します。そのため、常連客一覧は増え続けています。",
        },
      ],
      button: "詳しく見る",
    },
    tglGroup: {
      title: "2019年から企業活動のグローバル化",
      techStack: "テクノロジー",
      sub: "顧客様のご希望に応えるプロフェッショナルで効果的なテクノロジーソリューションを提供するTGL Solutionsが、国内外における多くの顧客様から信頼を獲得できたパートナーであることは我々の誇りです。",
      content: "若者のダイナミックで熱意のある創造的なワーク環境",
      branch: [
        {
          name: "skyACE株式会社",
          sub: "日本の東京にあるskyACE(TGL Solutionsの本社) は、世界中の多くの国からの情報技術分野の経験豊富な専門家の職場です。",
        },
        {
          name: "Prima Solutions会社",
          sub: "2022年4月に設立され、建設業界のデジタルトランスフォーメーションを目指し、建築、建設、機械の分野におけるCAD・BIM技術アプリケーションの開発に努力しています。",
        },
        {
          name: "TGL Solutions会社",
          sub: "設立以来、多くの課題に直面しながらも、会社の収益及び会員数は年々増加しています。 2022年の収益が前年比で200%増加するのはTGL Solutions会社の持続可能な発展のエビデンスです。",
        },
      ],
    },
    ourCertifications: {
      title: "私たちの賞と認定",
    },
    about: {
      title: "会社概要",
      sub: "TGL Solutionsは、2019年9月にLe Tien Trien博士によって設立されました。ベトナム人の精神を取り入れ、TGL Solutionsを通じて社会の発展に貢献したいと考えている Le Tien Trien氏。そのため、国と社会のために、最もスマートで最速のソリューションを作成するという意味を込めて、Smart & Speed Solutions は弊社のスローガンです。",
      btnView: "詳しく見る",
    },
    project: {
      title: "開発実績",
      sub: "TGL Solutionsは、お客様の要件を満たし、品質確保しながら最速の時間でプロジェクトを完了します。そのため、常連客一覧は増え続けています。",
      btnView: "開発実績一覧",
      clients: "クライアント",
      companyProjects: "プロジェクト",
    },
    contact: {
      title: "TGL Solutions会社へようこそ!",
      sub: {
        span: "TGL Solutions会社が目指している理想的な環境とは、メンバーみんなが自由に素晴らしい価値を創造し、会社の持続可能な発展のために積極的な変化をもたらし、社会に貢献できるのです。\n\nTGL Solutions会社はベトナム人の価値を上げるという理想を叶え、挑戦したいと考る人を探しているんです。 次世代の「First Penguin」になりたい方は、入社しよう。",
        p: "",
      },
    },
    BIMCAD_highlight: {
      title: "優れたBIM／CADプロジェクト",
      button: "デモ動画を見る",
      content1: {
        title: "PIPINGプロジェクト",
        sub: "独自コマンドを開発し、パイピング作業を効率化するデモです。",
      },
      content2: {
        title: "REVITプロジェクト",
        sub: "自動配筋するデモです。設計業務を大幅に改善できます。",
      },
      content3: {
        title: "AIが設計するあなたの夢のマイホームとはo",
        sub: "AIが設計する住宅はこれまでとどんなふうに違うのか、きっとますます夢が膨らむはずです！好みのワードをチェックするだけ、感性をもとにAIが施工例を瞬時で提案します。",
        button: "体験する",
      },
    },
  },

  products: {
    webCloud: {
      title: "ウェブクラウドシステム",
      items: [
        {
          content: "Work Bee - コンパクトなデジタル変革プラットフォーム",
          link: "https://work-bee.net/",
        },
        {
          content: "現場システム - 建設プロジェクトの総合管理",
          link: "https://bimcad-vietnam.com/vi/construction-software/vinacad",
        },
        {
          content: "オンサイト クラウド CDE オン クラウド",
          link: "https://bimcad-vietnam.com/bim-viewer/bim-web-viewer",
        },
      ],
    },
    bimCad: {
      title: "CAD/BIM ソフトウェア",
      items: [
        {
          content: "VinaCAD - ベトナムNo.1のCADソフトウェア",
          link: "https://vina-cad.com/",
        },
        {
          content: "VinaBIM - プロジェクトの保守と運用",
          link: "https://vina-cad.com/",
        },
        {
          content: "VinaQ - ボリュームの分析",
          link: "https://vina-cad.com/",
        },
        {
          content: "TakaCAD - 2D 構造図の展開",
          link: "https://vina-cad.com/request-code?product=takacad",
        },
        {
          content: "BrightBIM - Revit 用スチール レイアウト プラグイン",
          link: "https://vina-cad.com/request-code?product=brightbim",
        },
      ],
    },
  },

  about_us: {
    banner: {
      title: "ベトナム人の価値を上げよう！",
      sub: "最も快適な職場環境の作成、従業員が自分の可能性、個人の強み、およびキャリア志向を最大限に促進するため弊社は「人間中心主義」のもとで、会社のすべてのポリシー及び決定は、決まります。",
    },

    message: {
      title: "仕事を楽しんでくださいますように",
      sub: {
        first: "皆さんにお伝えしたいことが2つあります。",
        second:
          "まずは仕事を楽しんでください。楽しければ、仕事が自然に効果的になるはずです。当然、従業員がよく働けば会社は成長しますね。成長した会社はプロジェクトのボーナス、昇給、および他の福利厚生を全員に支払います。会社側にも従業員側にも利益をもたらします。これにより、会社の持続可能な発展サークルが生まれ、社会へ貢献します。",
        third:
          "第二、今日の急速に変化する世界の状況では、マネージャーだけでなく従業員も混乱し、どうすればいいか分からないと思います。その場合、常にTransformation（変化）、Trial & Error（試行錯誤）、Take the lead（先駆）の”3T”の精神を維持してください。",
        four: "",
      },
      acthor: "CEO、 Co-Founder、 Le Tien Trien博士",
      btnView: "もっと見る",
    },

    slogan: [
      "問題解決に最も効果的な方法を考えよう!",
      "もし間違っても随時にやり直せるため、先延ばしずアプローチを決めよう!",
      "”スマート”と”スピード”の組み合わせで最も効果的な”ソリューションズ”を生み出し、お客様に最適な価値をもたらす!",
    ],

    vision: {
      title: "ビジョン及びミッション",
      cards: [
        {
          title: "ビジョン",
          sub: "ベトナム人の価値を上げよう！",
          content:
            "「人間中心主義」でTGL Solutionsはメンバーを問題解決、プローなワークスタイル、絶え間なく鍛えることについての養成に努力しています。それにより、ベトナム人の価値を上げ、高いレベルのプロジェクトを担当でき、国の発展に貢献できます。",
        },
        {
          title: "ミッション",
          sub: "人を中心に考え、意思決定及び行動",
          content:
            "人は会社の最大の資産です。当然、メンバーの成長が会社の成長の原動力になります。成長した会社はプロジェクトのボーナス、昇給、および他の福利厚生を全員に支払います。会社側にも従業員側にも利益をもたらします。これにより、会社の持続可能な発展サークルが生まれ、社会へ貢献します。TGL Solutionsのビジョンが「ベトナム人の価値を上げよう」であることを実現しています!",
        },
      ],
    },

    value: {
      title: "コアバリュー",
      sub: "「人」と「和」のコアバリューに基づいて、塀社は常に従業員が潜在能力と強みを促進する最も快適な職場環境を構築します。",
      cards: {
        people: {
          title: "「人」",
          slogan: "S（安全）＞＞Q（品質）＞D（納期）＞C（コスト）",
          subject:
            "「人」は人間ということであり、TGL Solutionsの理念は人間中心主義を貫くことです。",
          content:
            "S（安全）：安全、最優先事項は、会社のメンバーみんなの健康です。S が最も重要で、Q よりも遥かに重要です。\nQ（品質）: 品質はD（納期）やC（コスト）より優先します。\n納品期限は大切ですが、品質を納期より優先するということです。お客さんに最もいいものを提供したいと思います。",
        },
        piece: {
          title: "「和」",
          subject:
            "TGL Solutionsがすべてのメンバーに前向き及び積極的な態度持つことを奨励し、お互いに手伝ってあげる健全な環境を構築します。",
          content:
            "TGL Solutionsは最適な解決策を見つけるため常に相反する意見及び議論を奨励します。実施計画が決定されたら、すべてのメンバーが組織目標に努力する必要があります。",
        },
      },
    },

    rule: {
      title: "仕事のルール",
      sub: "TGL Solutions会社のTGLはどんな言葉の略なのか知っていますか。",
      cards: [
        {
          title: "報告",
          sub: '"「T」-「Thong bao」の略で、「報告」という意味変更がある際、すべての関係のある人に報告"',
        },
        {
          title: "説明",
          sub: "「G」-「Giai thich」の略で、「連絡」という意味解決方法を簡単に見つけるため、提示する際には、目的を明確に連絡",
        },
        {
          title: "相談",
          sub: "「L」-「Luan ban」の略で、「相談」という意味多くのアイデアを結びつけることで、問題をより完全かつ最適に解決することができるため、全員で相談",
        },
      ],
    },

    ourFirstPenguin: {
      title: "会社のボードメンバーのひとり言",
      sub: "共に成長する",
      cards: [
        {
          name: "Pham Phu Cuong",
          avatar: "/images/aboutus/Cuong.png",
          position: "TGLソリューションズ最高技術責任者",
          content:
            "私の考えでは、働くということは社会に価値を創出するためのものです。誰かがある便利なサービスや製品を使うとき、それは他の誰かが生み出した価値の結果です。働くことは「資本に自分を売る」ことではなく、自身の知識やスキルを活かして価値を生み出し、その対価として報酬を得ることです。ですから、自己鍛錬を怠らず、能力を高めて、創出する価値をますます大きくしていきましょう。それにより、報酬も増え、周囲からの承認や満足感も得られるのです。.",
        },
        {
          name: "Bui Si Hiep",
          avatar: "/images/aboutus/Hiep.png",
          position: "CADソリューション部部長",
          content:
            "私はBIM/CADソフトウェア開発チームのリーダーとして、同僚と共に絶え間ない創意工夫を重ね、建設業界のプロセスを最適化し、建築家、エンジニア、施工業者の業務効率を向上させるための先進的な技術ソリューションを提供しています。私たちはBIM/CAD技術が建設業界に革命をもたらすと信じており、その実現に向けて日々努力を続けています。",
        },
        {
          name: "Le Tien Trien",
          avatar: "/images/aboutus/Trien.png",
          position: "TGLソリューションズCEO・創設者",
          content:
            "待ったり観察したりする代わりに行動を優先し、沈黙する代わりに意見を述べてください。TGL Solutionsでは、間違い選択がなく、何かを行うと事前に目的を明確にして、みんなで共有することは大丈夫です。",
        },
        {
          name: "Ngo Xuan Huy",
          avatar: "/images/aboutus/Huy.png",
          position: "スピード事業部",
          content:
            "「プロセスに従って実行すること」はIT分野の新技術問題の解決のキーだと信じています。メンバーたちみんなが想像できるし、元気になるし最も快適な職場環境を構築したいと思います。",
        },
        {
          name: "Le Be Ly",
          avatar: "/images/aboutus/Ly.png",
          position: "バックオフィスユニットリーダー",
          content:
            "会社の人事部の責任者として、メンバーの利益を自分の利益として確保するよう常に心がけています。会社の人事部の担当者として、メンバーの利益を自分のように確保するよう常に心がけています。努力と熱意を込めて、TGL Solutionsがメンバーが一緒に学び、一生懸命働き、一緒に楽しみ、お互いに成長できる場所になることを願っています。",
        },
      ],
    },

    morale: {
      title: "の精神",
      cards: [
        {
          title: "Transit - 変化",
          sub: "現在を改善するために、継続的に変更しなければなりません。変更するためには、従業員に積極的に意見を述べることが奨励し、さまざまな意見を尊重しなければなりません。",
        },
        {
          title: "Trial & error - 試行錯誤",
          sub: "世界は急速に変化しますので、先延ばす代わりに、行動してください。失敗に遭遇しても、原因を分析、克服・改善する方法を見つけます。",
        },
        {
          title: "Take the lead - 先駆",
          sub: "Be the first penguin! - チーム全体を変更に駆り立てる先駆者になりましょう。",
        },
      ],
    },

    welcome: {
      title: "ベトナム人の価値を上げよう！",
      sub: "価値作成のため、挑戦に興味を持っている人を探しております。",
      btn: "共に成長する!",
    },
  },

  people: {
    banner: {
      title: "TGL Solutionsの「ファーストペンギン」の話",
      sub: "世界には19種類以上のペンギンがいますが、TGL Solutions会社で最も多いのは「ファーストペンギン」です。愛らしく、弾力性があり、過酷な状況に耐え、先駆と突破口に果敢に挑戦する戦士です。",
    },

    firstPenguin: {
      btn: {
        seeMore: "もっと見る",
        seeLess: "隠す",
      },
      data: {
        title: "ファーストペンギン",
        welContent:
          "世界には19種類以上のペンギンがいますが、TGL Solutionsで最も多いのは「ファーストペンギン」です。この動物は、愛らしく、弾力性があり、過酷な状況に耐え、開拓と突破口に果敢に挑戦する戦士でもあります。",
        sub: "狩猟の季節になると、すべてのペンギンが氷山の頂上に集まり、近くに魚や危険がないか海を見渡します。しかし、どれも最初のジャンプへの恐怖を克服できるわけではありません。人間と同じように、動物も予期せぬリスクや危険に直面することを理解しています。崖から海までの距離はかなり遠いです。さらに、魚を見つけたり、何も見つけられなかったり、さらに悪いことにアシカに襲われたりすることもあります。\n\n 一番最初にジャンプするペンギンはどれ? \n\n そこで、「ファーストペンギン」が先駆者に選ばれました。大したことないことを少し観察した後、2 番目、3 番目、4 番目、5 番目のペンギンも海に飛び込みました。\n\n 間違えないでください。「ファーストペンギン」は愚かでも無謀でもありません。海に飛び込むことは常に危険ですが、計算されたものです。捕食者に襲われても、「ファーストペンギン」は生き残る方法を見つけることができます。そしてもちろんデジタル時代では「早いもの勝ち」のではないでしょうか。そしてもちろん「ファーストペンギン–ファーストイート」。それだけでなく、先頭のペンギンがあえて突破するとき、それはあえて変化する人々のグループをもたらします。",
        question:
          "誰かがファースペンギンになる必要があります。なぜ私ではないですか?なぜあなたではないですか?",
      },
      poem: [
        {
          title:
            "「あなたがファースペンギンでなければ、ニ番目のペンギンになりなさい。 次回、あなたはファースペンギンもできない、ニ番目のペンギンもできません。そのため今度は一所懸命に頑張り、ファースペンギンになりましょう。」",
          sub1: "TGL Solutions会社では、メンバーを「ペンギン」と呼び、あえて変更、挑戦する「ファーストペンギンになりましょう」を励まします。先延ばしにしないで、やってみよう！",
          sub2: "挑戦するのはリスクを遭って、何回も試行錯誤かもしれませんが、たしかに変更できます。いつの間にか、専門家になり、全体の変更を励ましす。",
          show: "続きを見る",
          hide: "折りたたむ",
        },
        {
          title:
            "「すべての信念を込めて目標を設定します。\nすべてのリスクを念頭に置いて計画します。\n途中の驚きを楽しんでください」",
          sub1: "先駆者を奨励するため、「ファーストペンギンになりましょう」という賞 （同社の年間3最大のTGLアワードの1つで、賞金は1週間以内の日本への旅行です）。",
          sub2: "ペンギンの先駆的な取り組みが常に成功するとは限りませんが、それは問題ありません。\n\n コンフォート ゾーンから抜け出す過程にある限り、あなたが誰であるか、どこにいるかは問題ではないため、実際に自分自身から進歩している限り、全体的な利益のためにそれを高く評価しています。 – 可能性を試し、間違いから学びながら間違いを犯す方法を学んでいます。\n\nそして、変更、試行錯誤、先駆は、会社がTGLのマスコットである「ペンギン」を通してすべてのメンバーに伝えたい3Tの精神です。 ",
          show: "続きを見る",
          hide: "折りたたむ",
        },
      ],
    },

    studyDevelop: {
      title: "学習及び育成",
      sub: "「人間中心主義」を方針に、スキルを磨く、研修組織などへの投資を最優先に取り組んでいます。",
      data: [
        {
          step: "日本語クラス",
          sub: "TGL Solutions会社では、顧客の大半が日本市場から来ているため、日本語を理解することは、プロジェクトを円滑に進めるためキーの1つになります。\n\n TGLの日本語クラスは、N1とN2の資格を持つ教師と日本人の教師とによって教えられます。",
        },
        {
          step: "英語クラス",
          sub: "現在、TGL Solutionsの英語クラスは、すべての従業員にとって最も便利になるように勤務時間後に会社のオフィスで開催されます。クラスは、メンバーの希望とスキルに応じて2つのレベルに分かれています。上級クラスは、ネイティブの先生に直接教えられます。",
        },
        {
          step: "「TGL GO TO JAPAN」プログラム",
          sub: "TGL Solutionsは、従業員の日本語の学習を促進するだけでなく、将来のブリッジSE/プロジェクトマネージャーに向こう挑戦したいペンギンの日本の夢を実現するのに役立ちます。\n\n メンバーは会社で直接日本語を勉強できます。その後、日本では、1年間日本語を勉強しながら、仕事をしています。卒業後、JLPT N2/N1 レベルを達成すると、学生はブリッジSE/プロジェクト マネージャーとして、日本の顧客と直接やり取りするプロジェクトに直接関与します。\n\n TGL Go To Japanは初の3シーズンを成功に開催し、それを何年にもわたって続けています。あなたのミッションは、自分自身に挑戦する情熱とオリエンテーションを持っていることだけです。すべてのTGLが任せてください。",
        },
        {
          step: "インテンシブトレーニングコース、セミナー、トークショー体験共有",
          sub: "TGL Solutions会社は、技術知識と効果的な作業方法を共有するために、部門とユニットがセミナーとトークショーを開催することを奨励しています。 さらに、TGL Solutions会社は専門家を招待、仕事と生活の知識と経験を共有しています。",
        },
        {
          step: "社内文化研修コース",
          sub: "「人和」は、メンバーのために健康で快適な職場環境を構築するためのTGLの2つのコアバリューです。TGL Solutionsに入社した初日に、メンバーは、定期的に編集および更新される文化ハンドブックを通じて、TGL Solutionsの職場文化を詳細に紹介され、文化テスト、文化、企業精神を体験する機会もあります。\n\n TGL カルチャー ハンドブックとテストを通じて、会社が築いてきた 変更、試行錯誤、先駆の精神が理解できます。それだけでなく、報告・説明・相談というTGLの業務文化も、TGL Solutionsのアイデンティティーを構成する要素となっています。",
        },
      ],
    },

    workplace: {
      title: "お互いの助け合える職場環境",
      sub: "TGL Solutions会社の特徴は若い人の力です。 会社では、常に社交的で楽しい雰囲気を感じることができます。「共に成長する」ために互​​いに助け合っています。",
      cards: {
        subOkrs:
          "各部門・ユニットは、常に内部の結束と共有を重要な目標としてOKRsに設定",

        subParty:
          "毎週のスポーツ活動（サッカー、バドミントン）、ワークショップ、毎月のハッピーアワー、社員旅行",

        subHeart: "心身の健康に気をつけ、定期的な健康診断",

        subEar:
          "常に会社が最初の目指す従業員と取締役会の間の透明　ー　ヒアリング　ー　シェリング",

        subGroup: "組織開発のイニシアチブと集団的意思決定を促進",
      },
    },

    develop: {
      title: "持続的な発展",
      sub: "持続的な発展がTGL Solutionsの目指すところです。「持続可能な発展サークル」は社章に反映され、社是や日々の活動の中で実現されています。持続的な発展のために、TGL Solutionsは人を中心にすべての決定と行動を行い、人材のトレーニングに集中し、要件を満たすために技術トレンドを「変更、試行錯誤、先駆」の精神通りに絶えず変更および更新しています。",
      conslusion:
        "当然、従業員がよく働けば会社は成長しますね。成長した会社はプロジェクトのボーナス、昇給、および他の福利厚生を全員に支払います。会社側にも従業員側にも利益をもたらします。これにより、会社の持続可能な発展サークルが生まれ、社会へ貢献します。",
    },

    welcome: {
      title: "ベトナム人の人間的価値を上げよう!",
      sub: "価値作成のため、挑戦に興味を持っている人を探しております。",
      btn: "共に成長する!",
    },
  },

  project_detail: {
    title_project_detail: "プロジェクト詳細",
    back: "戻る",
    not_found: "見つかりません",
    technical: "テクノロジー：",
    shareLabel: "プロジェクトリーダーからの共有",
    listProjectDetails: [
      {
        id: 1,
        title: "特発性肺線維症の診断を支援するAIシステム",
        category: "AI",
        pic: "Nguyen Duy Dat",
        avatar: "/images/members/Dat.jpg",
        tech: "技術: Python、Docker、Semantic Segmentation、Deep Learning、Image Processing",
        message:
          "このプロジェクトは、病気を早期に発見する可能性を高め、それによって患者の治療の可能性を高めます。",
        image: ["/images/ai/1_1.webp", "/images/ai/1_2.png"],
        content: [
          {
            title: "1. 問題や背景: ",
            content:
              "特発性肺線維症（IPF）とは？特発性肺線維症は肺が徐々に呼吸機能を失っていく状態で死に至ることが多いですが、病気の原因は不明ですので診断が難しい肺疾患です。今日まで、肺線維症の患者数に関する正確なデータはありません。2020年の時点で、米国では約180,000人の特発性肺線維症があり、毎年40,000人がこの病気で亡くなっています。2020年のアメリカの交通死亡者数の13年ぶりの最高水準は38,680人　－　特発性肺線維症で死亡する人の数よりもまだ少ないと見られます。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "通常、患者の平均生存期間は診断時から約3年です。AIを用いた高度な深層学習技術（DeepLabv3、HRnet）を使用し、病気の早期診断に役立つ、患者さんの生存期間を延長すること、従来よりも優れた結果をもたらすことが期待されています。",
          },
        ],
      },
      {
        id: 2,
        title: "外装エクステリアのスマートAI",
        category: "AI",
        pic: "Nguyen Duy Dat",
        avatar: "/images/members/Dat.jpg",
        tech: "技術: Python、Deep Learning、Image Segmentation、Digital Image Processing",
        message:
          "外装エクステリアデザインの需要が高まっているため、家のデコレーションのニーズに迅速に対応する自動化ソリューションを開発しました。",
        image: ["/images/ai/2_1.webp", "/images/ai/2_2.png"],
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "外装エクステリアを変更したい住宅所有者は通常、次の手順に従います: \n1. 家及び外装エクステリアフォームの写真を撮影\n2. 建築家は住宅所有者の要望に従ってフォトショップで編集\n3. 住宅所有者は、編集された写真を見て決定（外観の色を修正するには、少なくとも1日かかる）。 そして、当事者が話し合う時間は含まりません。 \n=>モバイルデバイスで写真を選択させ、外装の色を合わせてカスタマイズさせるAIテクノロジーを使用します。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "外観を認識し、要望の色を変更する機能で、30秒もかからずに思い通りの家のイメージができあがります。",
          },
        ],
      },
      {
        id: 3,
        title: "心臓病を音で診断するAIシステム",
        category: "AI",
        pic: "Nguyen Duy Dat",
        avatar: "/images/members/Dat.jpg",
        tech: "技術: Audio Processing、Auto cut-off frequency、Deep Learning、Audio Classification、Spectrogram、Python",
        message:
          "このプロジェクトは、ユーザーがいつでもどこでも心拍数で自分の健康を積極的にチェックし、現在の健康状態に適したアドバイスを提供するのに役立ちます。",
        image: ["/images/ai/3_1.webp", "/images/ai/3_2.png"],
        content: [
          {
            title: "1.　問題や背景:",
            content:
              "ユーザーは、心拍数をレコードすることで自分の健康状態を監視できます。 AIシステムは、ゲストがサーバーに送信するオーディオファイルで心臓病の診断を行います。それにより、顧客は自分の健康状態を積極的に監視し、できるだけ早く医療サポートを受けることができます。",
          },
          {
            title: "2. 機能や解決:",
            // content:
            //   '機能:\n- ユーザーの心拍数を正常または異常の診断 \n- ユーザーの心臓の問題を具体的に診断 \n\n メリット: \n- ユーザーが自分の健康状態を積極的にチェック可能 \n- 異常な心拍が発生の際に最寄りの医療施設に連絡し、位置情報と緊急事態宣言を自動的に発信 \n- 医師は発信されたデータに基づいて患者の心拍数の履歴にすばやくアクセス',
            content:
              "ユーザーの心拍数を正常または異常の診断 \nユーザーの心臓の問題を具体的に診断 \n- ユーザーが自分の健康状態を積極的にチェック可能 \n- 異常な心拍が発生の際に最寄りの医療施設に連絡し、位置情報と緊急事態宣言を自動的に発信 \n- 医師は発信されたデータに基づいて患者の心拍数の履歴にすばやくアクセス",
          },
        ],
      },
      {
        id: 4,
        title: "工場のメタバースプロジェクト",
        category: "メタバース",
        pic: "Nguyen Duy Dat",
        avatar: "/images/members/Dat.jpg",
        tech: "技術: Omniverse nvidia、Actorcore",
        image: ["/images/ai/4_1.webp", "/images/ai/4_2.jpg"],
        message:
          "非常に費用がかかり、多くの潜在的なリスクがある実際の工場を建設する代わりに、クライアントが完全な機能を備えた仮想世界の工場を、はるかに低コストでその操作性をテストできます。 \n そして、実物を訪れる前に仮想空間で病院、学校、工場を訪れることができます。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "現在、投資家とメーカーは、設置/製造プロセスに多くの時間、労力、およびお金を費やしています。設置の際に、計算が間違っていたり、発生したケースが制御されていなかったりすると、この問題のコストと手間が大幅にかかっています。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "DigitalTwinの目標は、完全な操作モードでリアリティのシミュレーションを実装し、製品または製造プロセスのリスクを予測するため、設置/製造の手間が大幅に省けます。\n仮想世界にアクセスし、費用が節約できます。",
          },
        ],
      },
      {
        id: 5,
        title: "CAD1プロジェクト",
        category: "BIM・CAD",
        pic: "Phan Tran Minh Tri",
        avatar: "/images/members/Tri.jpg",
        tech: "技術: C#、WPF",
        image: ["/images/cad/5_1.webp", "/images/cad/5_2.jpg"],
        message:
          "プロジェクト成功を達成するため、最初の要因は、全てのメンバーの成功だと思います。そのためには、具体的で明確な計画と、最善を尽くす意志が何卒必要のではないでしょうか。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "Autocadの強力で優れた開発とともに、Autocadと同様の幅広い機能をより安価で提供したいという願望を目指し、Gcad、BricsCad、DraftSightなどの多くの同様の開発製品が生まれました。 CADマケット寡占化の状況を打破するために、企業のお客様は、リーズナブルな価格で構築用のテクニカルサポートのソフトウェアを開発したいと考えています。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "CAD図面のコマンドを分析、設計、開発します。コントロールは、非常に高精度で建物、建築材料などの対象のシミュレーションと構築するために最適化され、建築設計の迅速し、作業効率を向上させます。",
          },
        ],
      },
      {
        id: 6,
        title: "NewCADプロジェクト",
        category: "BIM・CAD",
        pic: "Phan Tran Minh Tri",
        avatar: "/images/members/Tri.jpg",
        tech: "技術: .NET、AutoCAD API",
        image: ["/images/cad/6_1.webp", "/images/cad/6_2.jpg"],
        message:
          "このプロジェクトは現在プロトタイプを作成しているため、具体的な情報がありませんが、これは未来の潜在的なプロジェクトです。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "インフラストラクチャの設計需要が高まっている状況で、今日の絶え間なく変化する状況で融通に適応できる特定のテンプレートの通り自動的に開発するツールが必要です。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "これは家屋、橋、道路などの複雑度の高い道路設計を短時間で3Dモデリング描画するソフトウェアです。",
          },
        ],
      },
      {
        id: 7,
        title: "CAD4プロジェクト (COM・ActiveXに関連)",
        category: "BIM・CAD",
        pic: "Nguyen Van Ngoc Minh",
        avatar: "/images/members/Minh.jpg",
        tech: "技術: C++、COM/ActiveX",
        image: ["/images/cad/7_1.webp", "/images/cad/7_2.gif"],
        message:
          "COM・ActiveXは、個別のソフトウェア間のやり取りを最も便利で効率的に行うため、モジュール作成に利用されます。\nCOM・ActiveXを通じて他のソフトウェア自体をやり取らせるAPI公開を作成用にサポートします。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "他のモジュールをC#、VBなどの他の言語で記述するために公開する必要がある、C++で記述された一連のライブラリが既にあります。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "これらのCOM・ActiveXモジュールは、互いに独立的に活用でき、他のモジュールに操作することもできます。これらは、さまざまな言語で記載された非常に多様なモジュールです。COM・ActiveXのおかげで、個別に開発されたプログラムは、COM・ActiveXを介してさまざまな方法でリンクおよびやり取れます。",
          },
        ],
      },
      {
        id: 8,
        title: "Pipingプロジェクト",
        category: "BIM・CAD",
        pic: "Nguyen Van Ngoc Minh",
        avatar: "/images/members/Minh.jpg",
        tech: "技術: ObjectArx、C#",
        image: ["/images/cad/8_1.webp", "/images/cad/8_2.jpg"],
        message:
          "弊社はObjectArxのテクノロジを熟知しており、AutoCADのジョブをより正確・迅速・効率的に実行できるようにするためのアイデアとソリューションを実現し、効率を向上させ、顧客に多くの利益をもたらすことができます。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "CAD図面で行われるパイプラインシステムの設計作業は、依然として非常に難しく複雑であり、実装プロセスでエラーが発生する可能性があります。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "ユーザーにパイピングシステムをより安易・正確・迅速・効率的に作成させるようにするAutoCADコマンドを提供します。\nデモビデオのリンク: https://www.youtube.com/watch?v=Um098tO_u7c&t=1s",
          },
        ],
      },
      {
        id: 9,
        title: "Revitプロジェクト",
        category: "BIM・CAD",
        pic: "Nguyen Van Ngoc Minh",
        avatar: "/images/members/Minh.jpg",
        tech: "技術: RevitAPI、C#",
        image: ["/images/cad/9_1.webp", "/images/cad/9_2.jpg"],
        message:
          "BIMは建設業界の未来となる可能があります。Revitは BIM分野のトップのソフトウェアです。Revit APIテクノロジを熟知することで、弊社はRevitと完全にやり取りし、将来のBIM実装用の効率を最大化するアドオンを作成できます。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "建物の構造要素（柱、梁、床など）の金属レイアウトの設定は手動で行ったら、手間がかかり、エラーのリスクが高く、同時編集も難しくなります。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "ユーザーがコンポーネントの鋼材を迅速・正確に配置するのに役立つ機能を作成します。ユーザーは必要な数値を入力するだけで、ソフトウェアは入力および事前設定された情報に従って鋼を自動的に配置します。\nデモビデオのリンク: https://www.youtube.com/watch?v=TfuOUMMqvWo",
          },
        ],
      },
      {
        id: 10,
        title: "みらい市プロジェクト",
        category: "ウエブシステム",
        pic: "Nguyen Quy Le",
        avatar: "/images/members/Le.jpg",
        tech: "技術: Reactjs、Nextjs、Graphql、TypeOrm、Oracale",
        image: ["/images/web/10_1.webp", "/images/web/10_2.jpg"],
        message:
          "このプロジェクトを通じ、弊社は、顧客の要件を絶え間なくヒアリング、編集、設計することで、顧客が本当に最もいいものを提供したいと考えています。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "顧客は、会社の販売をサポートするためにeコマース サイトを作成したいと考えています。これはライブ配信、VRの機能を合わせ、販売を最適化させるシステムです。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "商品の注文・売買・ポイントを貯める機能\nVRショールームコーナー、メーカー商品紹介動画コーナー\nライブ配信機能：ユーザーは設定されたライブ配信を通じて販売者からの生放送を視聴し、直接購入することができます。\n商品オークション機能: ライブ配信者は商品オークションを開催でき、 ユーザーは商品のオークションに参加でき、落札者は落札価格で注文できます。\n管理ページ: 商品管理、注文管理、ポイントの蓄積、収入など； 注文ごと、製品ごと、ユーザーごとなどに Excel を出力； トップページ、管理ページにログインするためのクイックログインリンクを作成； ご注文状況やポイントの積算状況を、ご利用者様およびご注文担当者様へメールでお知らせするシステム",
          },
        ],
      },
      {
        id: 11,
        title: "Lesson Roomプロジェクト",
        category: "Webシステム",
        pic: "Phan Dang Lam",
        avatar: "/images/members/Lam.jpg",
        tech: "技術: HTML、CSS、JS、WebRTC、Skyway",
        image: ["/images/web/11_1.webp", "/images/web/11_2.jpg"],
        message:
          "Lesson Roomプロジェクトは難しい機能がそれほど多くありませんが、顧客エラーの多い古いソースコードから更に多くの機能を開発するプロジェクトであるため、チームがコードを最適化し、古いエラーを修正し、追加機能を開発するのに多くの困難が生じます。困難でありますが、チームはプロジェクトを完了し、顧客が希望する機能を実現できるように最善を尽くしました。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "現在、オンラインでの授業が人気を博しており、特に最近のコロナウイルスの流行により、ほとんどの人がオンラインでの授業に慣れてきました。そのため、多くの人々のニーズを満たすために、クライアント企業は、オンラインでの教育と学習を目的として、教室用の Web サイトを注文しました。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "1/ 教師と生徒間の 1対1のビデオ通話機能\n2/ 教科書の学習教材をリアルタイムで共有機能\n3/ 教師と生徒のやり取りが可能のホワイトボード機能\n4/ チャットおよびファイル送信機能\n5 / メモ機能\n6/ 画面共有機能\n7/ 画面録画機能\n8/ 背景フィルター機能",
          },
        ],
      },
      {
        id: 12,
        title: "YHKプロジェクト",
        category: "ウエブシステム",
        pic: "Phan Ngoc Thinh",
        avatar: "/images/members/Thinh.jpg",
        tech: "技術: React、Ant Design、C# .NET、RESTful API",
        image: ["/images/web/12_1.webp", "/images/web/12_2.jpg"],
        message:
          "TNFチームは、YHKという基幹システムのプロジェクトを担当いただき、非常に幸運だと思います。プロジェクトを実装中に、チームは多くの経験を重なってきて、後でより大規模プロジェクトに役に立つと思います。開発者として誰でも顧客に利益をもたらす製品を開発したいのではないでしょうか。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "YHKは倉庫管理に関わる業務を効果的にサポートする集中管理システムです。業務をより正確・迅速・効率的に行うため、顧客は、YHKを使用し、ほとんどの手作業を自動的にさせたいと考えています。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "発注、倉庫保管の作業を最適化します。\n納品書を自動的に発行します。\n定期的なレポートを自動的に作成し送信します。",
          },
        ],
      },
      {
        id: 13,
        title: "Spotlightプロジェクト",
        category: "ウエブシステム",
        pic: "Phan Ngoc Thinh",
        avatar: "/images/members/Thinh.jpg",
        tech: "技術: NextJS、Laravel、WebRTC",
        image: ["/images/web/13_1.webp", "/images/web/13_2.jpg"],
        message:
          "これは興味深く、非常に有望なプロジェクトです。 現在のビデオ通話システムは、多くのプラットフォームで非常に人気があり、インターネットを使用して相互に情報を交換する社会の状況でシステムに適用されます。 そして、システムは将来的には社会のすべての要件を満たすために更に拡大します。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "Spotlightは、さまざまなデバイスで 2 人のユーザー間のビデオ通話のシステムです。これは、最も簡単で効率的な方法で機能と通話管理を提供する、企業にとって費用対効果の高いソリューションです。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "ウエブブラウザー間、ウエブブラウザーとウエブアプリケーション間、ウエブアプリケーション間のビデオ通話に使用されます。\n中間サーバーを使用せずに 2つ以上のデバイス間でデータを交換できます。 nウエブ会議、カスタマー サポート、ゲーム、オンラインチュートリアルなどに使用されるWebPhoneを作成します。\nお客様側、企業側からの通信サービス等は無料です。\n画面共有機能により、簡単で安全的にお客様のサポ​​ートできます。\nskyACEが開発したSpotlightシステムはウエブブラウザーでユーザーと直接接続できるWebRTC（電話システム) の代表的なものです。",
          },
        ],
      },
      {
        id: 14,
        title: "Girls Barプロジェクト",
        category: "ウエブシステム",
        pic: "Phan Ngoc Thinh",
        avatar: "/images/members/Thinh.jpg",
        tech: "技術: DB: Aurora\nLaravel-ver9、PHP-ver8.1、Bootstrap CSS\nNGINX",
        image: ["/images/web/14_1.webp", "/images/web/14_2.jpg"],
        message:
          "これは内容と使用されているテクノロジーの両方に非常に面白いプロジェクトです。\n内容については、ガールズバーのようなユニークなサービスなどの日本のことをもっと学ぶ機会があります。\nテクノロジーに関しては、実際のプロジェクトに Lavarel を適用したのはこれが初めてです。 まだまだわからないことはたくさんありますが、大きなモチベーションになります。私たちはTNF（To New Future）ー　新技術を開拓するミッションを持っています。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "ガールズバーは、カウンター席に着席する客と相対する形で、カウンター内にいる女性バーテンダーが接客するタイプです。日本では、ガールズバーは非常に人気があり、日本文化が染み込んでいるサービスです。今日、仕事を探している人とバーとの間のジョブコネは効果的ではないと気づいてたため、ガールズバー専用のジョブコネシステムであるGirlsMeeeという製品の開発を支援しました。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "求職者向け：GirlsMeeeを使用すると、求職者は通勤に便利な仕事を見つけることができます。さらに、バーで体験入店した人は、体入レポートを投稿することもあるので他の求職者が参照できることもできます。\n求人者向け：要求に最もふさわしい応募者を探すため、バーオーナーは求人情報が掲載できます。",
          },
        ],
      },
      {
        id: 15,
        title: "Kensa Cloudアプリ",
        category: "スマホアプリ",
        pic: "Phan Ngoc Thinh",
        avatar: "/images/members/Thinh.jpg",
        tech: "技術: React、React Native、Ant Design、TailwindCSS、.NET",
        image: ["/images/app/15_1.webp", "/images/app/15_2.jpg"],
        message:
          "これは、弊社が希望を込めて、製品分野に参入する先駆的な製品プロジェクトの1つです。 あまり大きくない規模の製品ですが、「変更、試行錯誤、先駆」の精神を込めます。 未来、見事な成果を達成できるように最善を尽くします。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "通常、住宅プロジェクトの調査を実施し、写真を撮って結果をまとめ、上司に報告するには、手間がすごくかかります。投資者（不動産に投資する方）と工事会社の検収が不透明で、管理が非効率です。\n- 現場での実装には視覚的な比較が欠けているため、作業の品質を評価するのが難しいです。\n- 関係者間での検収後のデータを保存および同期の際に、一貫性がありません。 \n- 施工データと効率をリアルタイムで追跡するのは難しいです。\n以上の問題点の解決することを目的として、Kensa Cloudシステムを開発しました。 スマートフォンで簡単に検査を行い、レポートを自動作成するアプリです。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "1台の電話 (オンラインまたはオフライン)で作業検収を迅速・簡単に行い、従業員の効率を最適化します。\n検収の透明性を促進し、品質を直接改善します。\n検収担当者と管理者の間に効果的な作業環境を築きます。\nレポートを自動的に作成し、クラウドにワンクリックで送信します。更に、レポートの継承とカスタマイズ機能が強力です。",
          },
        ],
      },
      {
        id: 16,
        title: "BLEアプリ",
        category: "スマホアプリ",
        pic: "Huynh Huy Tuan",
        avatar: "/images/members/Tuan.jpg",
        tech: "技術: Flutter、Sqlite、BLE、IOT",
        image: ["/images/app/16_1.webp", "/images/app/16_2.jpg"],
        message:
          "知識と経験により、私たちは人力を軽減するための技術ソリューションを提供することを目指しています。お客様の満足は私たちの幸せであり、製品を完成させ、開発するためのモチベーションです。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "料理業界では、食品や飲料の温度を測定および監視することが非常に重要です。ただし、温度が高すぎたり低すぎたり、測定装置の故障やバッテリー不足などの問題を回避するため、監視する必要なので、多くの労力がかかります。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "ワイヤレスとChino MF100センサーを組み合わせた Bluetoothにより、これは長期的な温度監視と合計するための優れたソリューションとなります。\nアプリケーションには以下の主な機能が含まれています: \n- 複数のセンサーの温度をリアルタイムで監視および表示します。\n-センサーとの接続を管理します。\n- 測定結果を日付、測定時間ごとに詳細的にに合計します。\n- 異常がある場合は音を鳴らしたり、メールを送信したりします。 \n- 限界温度、センサーエラーなどの異常の融通なパラメーター設定します。",
          },
        ],
      },
      {
        id: 17,
        title: "EPCOアプリ",
        category: "スマホアプリ",
        pic: "Phan Huynh Minh Duy",
        avatar: "/images/members/Duy.jpg",
        tech: "技術: React Native、.NET Core、MySQL、Firebase",
        image: ["/images/app/17_1.webp", "/images/app/17_2.png"],
        message:
          "チームのメンバーは経験がほとんどありません（はじめに経験がほぼゼロ）が、プロジェクトを実行中で自分の役割を最善を尽くしました。 チームは常に積極的に新しい知識を学び、プロジェクトに適用し、顧客に価値をもたらします。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "御客さん（不動産会社）は、顧客と従業員とのコミュニケーションを目的としたスマートフォン アプリケーションを開発し、徐々に他機能を追加していきたいと考えられます。",
          },
          {
            title: "2. 機能や解決:",
            content:
              " 顧客と従業員のコミュニケーション (ファイル、写真、ビデオの送信を含む)を応援\n- 家の修理/定期検査要求を作成\n- 情報、指示などを表示したりの他の機能\n- セキュリティ強化を実装し、プロジェクトの高度なセキュリティを確保\niOSとAndroidの両方のプラットフォームでモバイルアプリをすばやく設計し、企業が開発とメンテナンスの手間、コストを節約",
          },
        ],
      },
      {
        id: 18,
        title: "Ikkankaプロジェクト",
        category: "PCアプリケーション",
        pic: "Nguon Man",
        avatar: "/images/members/Man.jpg",
        tech: "技術: C# .NET (WPF MVVM)、.NET CORE (Oracle)",
        image: ["/images/app/18_1.webp", "/images/app/ikkanka_1.jpg"],
        message:
          "すべての産業がテクノロジーを適用するインダストリー4.0時代の中で、企業は経済の発展に追いつくために変化を余儀なくされています。 すべての改善とアップグレード（人材の増加、設備のアップグレードなど）が可能ですが、時間が限定ですので、最適化できれば、それが最善の解決策になります。",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "インダストリー4.0の時代では、すべてをデジタル化する必要があり、その中で企業が人力と時間を節約し、それにより生産性/仕事の質を向上させるのにロジスティクスは役立つ非常に重要な要素です。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "迅速・効率的に商品の注文と配送を行うためのロジスティクス ソリューション (距離、時間、人力の最適化)を顧客に提供します。",
          },
        ],
      },
      {
        id: 19,
        title: "Shuhoプロジェクト",
        category: "PCアプリケーション",
        pic: "",
        avatar: "/images/members/penguin.png",
        image: ["/images/app/19_1.webp", "/images/app/suho_1.jpg"],
        tech: "技術: C# .NET (WPF MVVM)、.NET CORE (Oracle)",
        message: "",
        content: [
          {
            title: "1. 問題や背景:",
            content:
              "週報を作成するには、顧客はウエブサイトにアクセスしてデータを分析し、データに応じて表、グラフなどの要約 Excel ファイルを作成するなどの手動操作でレポートを作成します。データによって、レポートを印刷し、上司に渡する必要があります。 そのため、週報の手動方法を最適化し、Web アプリケーションのような自動更新機能を備えた PC アプリケーションを開発したいと考えていました。",
          },
          {
            title: "2. 機能や解決:",
            content:
              "このアプリは、以下の機能により、週報作成時間を3時間から10分に最適化:\n- 週報に最も関連性の高いデータの自動収集をサポート\n- Excelファイルに深く介入し、レポートファイルを作成してエクセルとして出力",
          },
        ],
      },
    ],
  },

  recruiment: {
    banner: {
      title: "TGL Solutions会社へようこそ!",
      sub: "TGL Solutions会社が目指している理想的な環境とは、メンバーみんなが自由に素晴らしい価値を創造し、会社の持続可能な発展のために積極的な変化をもたらし、社会に貢献できるのです。\n\nTGL Solutions会社はベトナム人の価値を上げるという理想を叶え、挑戦したいと考る人を探しているんです。 次世代の「First Penguin」になりたい方は、入社しよう。",
    },

    jobOpenings: {
      title: "求人情報",
      btnShowAll: "募集中情報を全て見る",
      listjob: [
        {
          id: 1,
          level: "経験の3年以上",
          position: "日本語ブリッジSE",
          sub: "市場競争力",
          extra: "フレキシブル",
          content: [
            {
              title: "概要",
              description:
                "***注: ベトナムでの支店と日本での本社に募集\n • 日本人の顧客からのリクエストを受け取り、顧客と打ち合わせ、プロジェクトの進捗状況を報告/議論\n • 顧客と話し合い、プロジェクトに最適なソリューション・テクノロジを選択\n • 多くの異なるドメインを持つプロジェクトの用件定義、基本設計を実装(各ドメインにはテックリードがいます)\n • 進捗を監視および管理。プロジェクト管理し、顧客と直接 Q&A 交換を実施し、プロジェクト開発チームとのコミュニケーション\n • 日本で働く応募者向け: プロジェクト管理、他の管理に経験不足のブリッジSEをサポート、会社のプロジェクトを管理（BrSEリーダー・デリバリー マネージャーとして長年の経験を持つ応募者に向け）",
            },
            {
              title: "要求",
              description:
                "• IT・コンピューター サイエンスまたは関連分野を卒業\n • ブリッジSE/プロジェクト マネージャーとして3年以上プロジェクトに直接参加した経験\n • 日本語N1または同等のN1\n • 要件を分析/システム開発可能\n • リーダーシップが利点\n • 高い責任感",
            },
            {
              title: "権利",
              description:
                "- 給与:\n　• 試用期間は１ヶ月で、100％を受取\n　• １年に２回の昇給 (昇給はその年の6月と12月にすぐに適用される)\n\n- ボーナス: \n　• メンバーに向け: ボーナス年2回：6月給与最大0.5ヶ月分+12月給与最大1ヶ月分（給与係数は新給与に応じる）\n　• 管理者向け：ボーナス年2回：6月給与最大1ヶ月分+12月給与最大2ヶ月分（給与係数は新給与に応じる）\n\n- その他福利厚生: \n • 1年間に13日間の休暇\n　• 1週に回の社内サッカー。 eスポーツ活動; チームビルディング; 誕生日パーティー、社員旅行\n　• 誕生日、祝日、新年のボーナス…\n　• 法律に基づくその他の制度。\n　• 無料の軽食、コーヒー、紅茶…\n\n- 教育制度:\n　• 外国語手当、コース手当、合格証明書および国際学位のボーナス\n　• 無料の Udemy コース；外国語クラス；経験を共有するためのセミナー、トークショー；専門家を会社で直接トレーニングするよう招待\n　• TGL Go To Japan プログラム – 日本で勉強して働くプログラム\n　• 会社で直接英語と日本語のクラス。\n\n- 環境:\n　• 柔軟な勤務時間 (作業月曜日から金曜日まで)、WFH は1月に5日\n　• 若く相互に協力する同僚たち、快適な職場環境と\n　• 従業員に常に学習の精神を促進させ、社員の強みを発揮させるチャンス作成",
            },
          ],
        },
        {
          id: 2,
          level: "全てのレベル",
          position: "LTV .NET C#",
          sub: "市場競争力",
          extra: "フレキシブル",
          content: [
            {
              title: "概要",
              description:
                "- 主な内容:\n • 要件定義や仕様書に従ってコマンドを開発\n • 添付のプロジェクト ビデオを参照: \nhttps://www.youtube.com/watch?v =opHXXkdOC5w&list=PLYWZmjpuHQMxs-FMWjBpNYmxvDqAQNA7E\n \n- プロジェクトに参加する理由\n　• コードに関する論理的思考のトレーニング: 最も論理的、科学的、かつわかりやすい方法でのコーディング\n　• 初期の要件分析から製品デプロイまでのソフトウェア開発プロセスへの参加\n　• さまざまな開発テクニックとコード デザイン ルールを学び。\n　• 自分自身のワークフロー、ドキュメントの処理・管理方法、効果的なチームワークをトレーニング: コムター、テスター、チーム リーダー、プロジェクト リーダー、および顧客とのコミュニケーション\n\n- 職務内容:\n　• 要件定義（ドキュメント、仕様書）に従ってコマンドを開発\n　• 割り当てられたコーディング コマンドを調査、設計、完了時間の見積もり、実行\n　• 仕様書に従ってセルフチェック/単体テストを実行\n　•シニア/プロジェクト リーダーからのフィードバックに従って、完全なコード ロジックを更新\n　• テスターとクライアントから報告されたバグを修正\n　• 割り当てられたコマンドを完了し、プロジェクト リーダーに報告 n　• 毎週の会議に出席し、進捗状況、困難、計画を報告\n　•スマートおよびスピードの基準に従って、TL/DM の要求に応じてタスクを実行",
            },
            {
              title: "要求",
              description:
                "• IT・コンピューターサイエンスまたは関連分野を卒業\n　• OOPを熟知\n　• C#プログラミング言語の経験\n　• WPF を使用し、フォームを構築および設計可能\n　• データ構造とアルゴリズムに関する優れたスキル n　•​​ 論理的思考と優れた問題解決能力\n 　• グループでも独立でも作業を行い\n　• 高い責任感",
            },
            {
              title: "権利",
              description:
                "- 給与:\n　• 試用期間は１ヶ月で、100％を受取\n　• １年に２回の昇給 (昇給はその年の6月と12月にすぐに適用される)\n\n- ボーナス: \n　• メンバーに向け: ボーナス年2回：6月給与最大0.5ヶ月分+12月給与最大1ヶ月分（給与係数は新給与に応じる）\n　• 管理者向け：ボーナス年2回：6月給与最大1ヶ月分+12月給与最大2ヶ月分（給与係数は新給与に応じる）\n\n- その他福利厚生: \n • 1年間に13日間の休暇\n　• 1週に回の社内サッカー。 eスポーツ活動; チームビルディング; 誕生日パーティー、社員旅行\n　• 誕生日、祝日、新年のボーナス…\n　• 法律に基づくその他の制度。\n　• 無料の軽食、コーヒー、紅茶…\n\n- 教育制度:\n　• 外国語手当、コース手当、合格証明書および国際学位のボーナス\n　• 無料の Udemy コース；外国語クラス；経験を共有するためのセミナー、トークショー；専門家を会社で直接トレーニングするよう招待\n　• TGL Go To Japan プログラム – 日本で勉強して働くプログラム\n　• 会社で直接英語と日本語のクラス。\n\n- 環境:\n　• 柔軟な勤務時間 (作業月曜日から金曜日まで)、WFH は1月に5日\n　• 若く相互に協力する同僚たち、快適な職場環境と\n　• 従業員に常に学習の精神を促進させ、社員の強みを発揮させるチャンス作成",
            },
          ],
        },
        {
          id: 3,
          level: "経験要求なし",
          position: "LTV C++",
          sub: "市場競争力",
          extra: "フレキシブル",
          content: [
            {
              title: "概要",
              description:
                "- 主な内容:\n　• ODA(C++/CLIで)プラットフォームに基づくCADシステム用の APIを作成と 、 APIでコマンドを改善\n　• 要件定義や仕様書に従ってコマンドを開発\n\n- プロジェクトに参加する理由？\n　• コードに関する論理的思考のトレーニング: 最も論理的、科学的、かつわかりやすい方法でのコーディング\n　• 初期の要件分析から製品デプロイまでのソフトウェア開発プロセスへの参加\n　• さまざまな開発テクニックとコード デザイン ルールを学び\n　• 自分自身のワークフロー、ドキュメントの処理・管理方法、効果的なチームワークをトレーニング: コムター、テスター、チーム リーダー、プロジェクト リーダー、および顧客とのコミュニケーション n\n- 職務内容:\n　• 要件定義（ドキュメント、仕様書）に従ってコマンドを開発\n　• 割り当てられたコーディング コマンドを調査、設計、完了時間の見積もり、実行\n　• 仕様書に従ってセルフチェック/単体テストを実行\n　• シニア/プロジェクト リーダーからのフィードバックに従って、完全なコード ロジックを更新\n　• テスターとクライアントから報告されたバグを修正\n　• 割り当てられたコマンドを完了し、プロジェクト リーダーに報告 n　 • 毎週の会議に出席し、進捗状況、困難、計画を報告\n　• スマートおよびスピードの基準に従って、TL/DM の要求に応じてタスクを実行",
            },
            {
              title: "要求",
              description:
                "• IT/コンピュータ サイエンスまたは関連分野を卒業\n　• C/C++ プログラミング言語を熟す\n　• OOP を知識、ObjectArx ライブラリを使用の経験が利点\n　• データ構造とアルゴリズムに関する優れたスキル\n　• 論理的思考と優れた問題解決能力\n　• グループでも独立でも作業を行い\n　• 高い責任感",
            },
            {
              title: "権利",
              description:
                "- 給与:\n　• 試用期間は１ヶ月で、100％を受取\n　• １年に２回の昇給 (昇給はその年の6月と12月にすぐに適用される)\n\n- ボーナス: \n　• メンバーに向け: ボーナス年2回：6月給与最大0.5ヶ月分+12月給与最大1ヶ月分（給与係数は新給与に応じる）\n　•管理者向け：ボーナス年2回：6月給与最大1ヶ月分+12月給与最大2ヶ月分（給与係数は新給与に応じる）\n\n- その他福利厚生: \n • 1年間に13日間の休暇\n　• 1週に回の社内サッカー。 eスポーツ活動; チームビルディング; 誕生日パーティー、社員旅行\n　• 誕生日、祝日、新年のボーナス…\n　• 法律に基づくその他の制度。\n　• 無料の軽食、コーヒー、紅茶…\n\n- 教育制度:\n　• 外国語手当、コース手当、合格証明書および国際学位のボーナス\n　• 無料の Udemy コース；外国語クラス；経験を共有するためのセミナー、トークショー；専門家を会社で直接トレーニングするよう招待\n　• TGL Go To Japan プログラム – 日本で勉強して働くプログラム\n　• 会社で直接英語と日本語のクラス。\n\n- 環境:\n　• 柔軟な勤務時間 (作業月曜日から金曜日まで)、WFH は1月に5日\n　• 若く相互に協力する同僚たち、快適な職場環境と\n　• 従業員に常に学習の精神を促進させ、社員の強みを発揮させるチャンス作成",
            },
          ],
        },
        {
          id: 4,
          level: "経験の1年以上",
          position: "LTV BACK - END (NODEJS)",
          sub: "市場競争力",
          extra: "フレキシブル",
          content: [
            {
              title: "概要",
              description:
                "- プロジェクトに参加の際のチャンス:\n 1. プロジェクト開発プロセスはアジャイル モデルに従い、CI/CD、AWSを使用\n 2. より多くの経験を重ねるために、 異なるBE言語（C#、Nodejs、PHP）のプロジェクトに参加\n 3. ビジネスを、データ構造を設計を理解するため、初期の要件分析から製品デプロイまでのソフトウェア開発プロセスの参加\n \n- 職務内容:\n　• クラウド ソリューション部門に参加 - E - com、ビジネス システム、AR、ライブストリーム、教育ウェブなどの多様なドメインで会社のウェブサイトを開発\n　• バックエンド部分のウェブサイトの開発、システム問題の分析、処理、解決を担当\n 　• 他のチーム メンバー間で相互にコードをクロスレビュー\n　• プロジェクトに関連するドキュメントを作成\n • チーム メンバーと協力して高品質の製品を作成\n　• フロントエンド デベロッパーやウェブ デザイナーと協力し、設計要件を分析し、顧客の要件に従って機能を開発\n • 新しいメンバー、経験の浅いメンバーをサポート（経験のあるメンバー向け）\n　• プロジェクト管理、チーム発展のことについて、上司と話し合い\n　• 定期的なプロジェクト ミーティングに参加",
            },
            {
              title: "要求",
              description:
                "• NodeJS テックの使用経験 (NestJS についての知識が利点)\n　• AWSなどのクラウド サービス (ま​​たは Microsoft Azure、Google Cloud Platform など) の使用経験\n　• Restful API の記述に習熟、GraphQL についての知識が利点\n　• Oracle SQL、MySQL、SQL ServerなどのSCDLの経験\n　• Object Relational Mapping の知識、TypeOrm、Entity Frameworkなどのライブラリーで作業\n　• IIS、Nginx などのウェブ サーバーまたは、Visual studio、Visual StudioCode、GIT、Sourcetreeなどのサポート ツールを構成\n　• 論理的に考え、問題を解決する能力、チームワーク",
            },
            {
              title: "権利",
              description:
                "- 給与:\n　• 試用期間は１ヶ月で、100％を受取\n　• １年に２回の昇給 (昇給はその年の6月と12月にすぐに適用される)\n\n- ボーナス: \n　• メンバーに向け: ボーナス年2回：6月給与最大0.5ヶ月分+12月給与最大1ヶ月分（給与係数は新給与に応じる）\n　•管理者向け：ボーナス年2回：6月給与最大1ヶ月分+12月給与最大2ヶ月分（給与係数は新給与に応じる）\n\n- その他福利厚生: \n • 1年間に13日間の休暇\n　• 1週に回の社内サッカー。 eスポーツ活動; チームビルディング; 誕生日パーティー、社員旅行\n　• 誕生日、祝日、新年のボーナス…\n　• 法律に基づくその他の制度。\n　• 無料の軽食、コーヒー、紅茶…\n\n- 教育制度:\n　•外国語手当、コース手当、合格証明書および国際学位のボーナス\n　• 無料の Udemy コース；外国語クラス；経験を共有するためのセミナー、トークショー；専門家を会社で直接トレーニングするよう招待\n　• TGL Go To Japan プログラム – 日本で勉強して働くプログラム\n　• 会社で直接英語と日本語のクラス。\n\n- 環境:\n　• 柔軟な勤務時間 (作業月曜日から金曜日まで)、WFH は1月に5日\n　• 若く相互に協力する同僚たち、快適な職場環境と\n　• 従業員に常に学習の精神を促進させ、社員の強みを発揮させるチャンス作成",
            },
          ],
        },
        {
          id: 5,
          level: "経験の0～12ヶ月以上",
          position: "LTV MOBILE",
          sub: "市場競争力",
          extra: "フレキシブル",
          content: [
            {
              title: "概要",
              description:
                "• スマートフォン アプリ開発チームに参加\n　• プロジェクト開発プロセスに最初から参加、要件を分析、設計、コーディング、デプロイ\n　• タスクを取得、完了時間を見積もり、コーディングを実行、セルフテストを行い、デプロイ \n　• 毎週の会議に出席、進捗状況を報告、プロジェクト関連の問題について話し合い",
            },
            {
              title: "要求",
              description:
                "• IT/コンピュータ サイエンスまたは関連分野の学位プログラムを修了\n • React Native の知識と経験\n • .NET バックエンドの知識が利点\n • 論理的思考と優れた問題解決能力\n • 高い責任感",
            },
            {
              title: "権利",
              description:
                "- 給与:\n　• 試用期間は１ヶ月で、100％を受取\n　• １年に２回の昇給 (昇給はその年の6月と12月にすぐに適用される)\n\n- ボーナス: \n　• メンバーに向け: ボーナス年2回：6月給与最大0.5ヶ月分+12月給与最大1ヶ月分（給与係数は新給与に応じる）\n　• 管理者向け：ボーナス年2回：6月給与最大1ヶ月分+12月給与最大2ヶ月分（給与係数は新給与に応じる）\n\n- その他福利厚生: \n • 1年間に13日間の休暇\n　• 1週に回の社内サッカー。 eスポーツ活動; チームビルディング; 誕生日パーティー、社員旅行\n　• 誕生日、祝日、新年のボーナス…\n　• 法律に基づくその他の制度。\n　• 無料の軽食、コーヒー、紅茶…\n\n- 教育制度:\n　•外国語手当、コース手当、合格証明書および国際学位のボーナス\n　• 無料の Udemy コース；外国語クラス；経験を共有するためのセミナー、トークショー；専門家を会社で直接トレーニングするよう招待\n　• TGL Go To Japan プログラム – 日本で勉強して働くプログラム\n　• 会社で直接英語と日本語のクラス。\n\n- 環境:\n　• 柔軟な勤務時間 (作業月曜日から金曜日まで)、WFH は1月に5日\n　• 若く相互に協力する同僚たち、快適な職場環境と\n　• 従業員に常に学習の精神を促進させ、社員の強みを発揮させるチャンス作成",
            },
          ],
        },
      ],
    },

    keyBenefit: {
      title: "TGL Solutionsのメンバーのメリット",
      cards: {
        internal: {
          title: "社内のトレーニング",
          sub: "「ベトナム人の価値を上げよう！」というビジョンのもと、TGL Solutions会社は経験を共有するためのトークショー及びセミナーを開催したり、専門家を会社で直接クラスを行いに招待したり、常に社内トレーニング活動に力を尽くしております。",
        },
        hours: {
          title: "柔軟な労働時間",
          sub: "都合がいい勤務時間と自分の体内時計に合わせて、自由に働くことができます。",
        },
        salary: {
          title: "魅力的な給与水準",
          sub: "1年に2回の昇給及び1年に2回のボーナスを受け取ります。",
        },
        activity: {
          title: "企業活動",
          sub: "毎週の男子サッカー活動、毎月のハッピーアワー、社員旅行、定期健康診断を行います。",
        },
        workhome: {
          title: "在宅勤務",
          sub: "各従業員は、1月に5日、オフィスに行かずに自宅で仕事ができます。",
        },
        environment: {
          title: "積極的な職場環境",
          sub: "尊 ・ 和 ・ 快 - 3つの要素を重視します。",
        },
      },
    },

    poem: { title: "仕事に楽しんでくださいますように" },

    welcome: {
      title: "ベトナム人の価値を上げよう！",
      sub: "価値作成のため、挑戦に興味を持っている人を探しております。",
      btn: "共に成長する!",
    },

    detailJobs: {
      benefitPackage: [
        {
          title: "福利厚生",
          sub: null,
        },
        {
          title: "メンタルヘルスケア",
          sub: [
            "従業員の定期健康診断を行い",
            "特別な日に従業員にギフトを贈り",
            "スポーツ活動への参加を奨励",
          ],
        },
        {
          title: "学習及びトレーニング",
          sub: [
            "毎週セミナーを開催",
            "あらゆる職種で成長し、挑戦する能力を発揮",
          ],
        },
        { title: null, sub: [] },
        {
          title: "イベント",
          sub: [
            "メンバーの誕生日とハッピーアワーを毎月開催",
            "各チームに活動バジェットを提供",
            "ピクニック、忘年会を開催",
          ],
        },
        {
          title: "賞与",
          sub: [
            "優秀なチームに賞与するため、四半期ごとに TGL スポットアワードを開催",
            "優秀な個人3名、チーム1つを賞与するため、年末にTGLアワードを開催",
          ],
        },
        { title: null, sub: [] },
        { title: null, sub: [] },
        {
          title: "インフラストラクチャー",
          sub: [
            "オフィスにはコーヒー、お菓子を常備",
            "作業デバイスを提供",
            "オフィスはホーチミン市の都心にあり、飲食店も勤務も便利",
          ],
        },
      ],

      similarOpportunities: {
        title: "他のジョブ",
        cards: [
          {
            title: "Front-end Engineer",
            position: "Junior",
            sub: "市場競争力",
          },
          {
            title: "Front-end Engineer",
            position: "Junior",
            sub: "市場競争力",
          },
          {
            title: "Front-end Engineer",
            position: "Junior",
            sub: "市場競争力",
          },
        ],
      },
    },
  },

  contact: {
    title: "お問い合わせください！",
    welcome: "ファーストペンギンになりましょう。",
    sub: "TGL Solutions会社のメンバーになるため、以下の情報をご入力してください。弊社は連絡致しますので、少々お待ちください。",
    cards: [
      {
        title: "所在地",
        sub: [
          "ベトナム、ホーチミン市ビンタイン区25坊ウン・ヴァン・キエム通り42/1　BCONS Tower 2ビル（5F)",
        ],
      },
      {
        title: "営業時間 ",
        sub: [
          "月曜日　- 金曜日: 8時30分　-　18時00分",
          "フレキシブルの勤務時間",
        ],
      },
      {
        title: "電話番号 ",
        sub: ["028 2200 2019"],
      },
      {
        title: "メールアドレス",
        sub: ["hr@tgl-sol.com"],
      },
    ],
    dataFormMail: {
      title: "お問い合わせ",
      subTitle:
        "最も快適な職場環境の作成、従業員が自分の可能性、個人の強み、およびキャリア志向を最大限に促進するため、会社のすべてのポリシー及び決定は、「人間中心主義」のもとで決まります。",
      name: "氏名",
      email: "メールアドレス",
      phone: "電話番号",
      website: "サイトなど ",
      network: "Facebook, LinkedIn ...",
      other: "他",
      message: "あなたのメッセージ",
      upload_file: "ファイル",
      skills: "スキル",
      birthday: "誕生日",
      upload_cv: "+ファイルをアップロードする",
      content_cv:
        ".PDF/.DOC/.DOCX/.JPG/.PNG/.ZIP形式のせいぜい1ファイルを送信してください。ファイルの1つの最大送信サイズが10MBです。",
      submit: "送信",
      status: "ステータス",
      success: "送信に成功しました！",
      fail: "送信に失敗しました！",
      file_wrong: "pdf, doc, docx.指定してください",
      file_success: "ファイルは正常にアップロードされました",
      file_size:
        "ファイルサイズが大きすぎます。 10MB 未満のファイルを選択してください。",
      required: {
        name: "氏名を入力してください。",
        error_name: "名前をローマ字とスペースのみ入力してください。",
        email: "メールを入力してください。",
        phone: "電話番号を入力してください。",
        cv: "CV必須です。",
        error_number: "電話番号は 数字で10 ～ 12 桁入力してください。",
        error_email: "有効なメールを入力してください。",
      },
      other_file: "他のファイルをアップロードする",
      other_file_list: "リストファイル:",
      delete: "消去",
    },
  },

  footer: {
    award: {
      title: "クオリティとセキュリティ",
      description: "は私たちの最優先事項です",
    },
    contents: [
      {
        title: "オフィス",
        content11: "5階, ビーコンズⅡ",
        content12: "42/1ウン・ヴァン・キーム , 25坊, ビンタン区、 ホーチミン市",
        ref1: "https://www.google.com/maps/place/TGL+Solutions/@10.803317,106.7129046,17z/data=!3m1!4b1!4m5!3m4!1s0x3175296c9e4131e9:0xf693c3afeb7bfe46!8m2!3d10.8033117!4d106.7150933",
      },
      {
        title: "お問い合わせ",
        content1: "hr@tgl-sol.com",
        ref1: "mailto:hr@tgl-sol.com?subject=subject&body=body",
        content2: "028 2200 2019",
        ref2: "tel: 02822002019",
      },
      {
        title: "SNS",
        content1: "TGL Solutions",
        ref1: "https://www.facebook.com/TGLSolutions",
        content2: "https://tgl-sol.com",
        ref2: "/",
        content3: "TGL TV",
        ref3: "https://www.youtube.com/channel/UCyhiHfIqwnXJhoGVKmlUEPA",
      },
    ],
  },

  detailJobs: {
    salary: "Mức lương",
    experience: "Kinh nghiệm",
    level: "Vị trí",
  },
};

export default cn;
