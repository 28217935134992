<template>
  <div
    class="container-navbar"
    v-bind:class="pathname[1] === '' ? 'home-mobile' : ''"
  >
    <router-link to="/" class="logo-smartphone">
      <img
        v-if="pathname[1] !== '' && !pathname[2]"
        src="/images/icons/header/logo-full.svg"
        class="w-100 h-100"
        alt="logo-full"
      />
    </router-link>
    <router-link to="/" class="logo-smartphone view-scroll">
      <img
        v-if="pathname[1] === '' || pathname[2]"
        src="/images/icons/header/logo-full.svg"
        class="w-100 h-100"
        alt="logo-mobile"
      />
    </router-link>
    <router-link to="/" class="logo-smartphone hide-scroll">
      <img
        v-if="pathname[1] === ''"
        src="/images/icons/header/logo-full-white.svg"
        class="w-100 h-100"
        alt="logo-mobile"
      />
    </router-link>

    <div id="wrapper-navbar">
      <div class="container-header col-sm-11">
        <div class="logo">
          <router-link class="navbar-brand js-scroll-trigger" to="/">
            <img
              src="/images/icons/header/logo-full.svg"
              class="image-desktop-navbar"
              @click="clickMenu('/', 0)"
              alt="logo-full"
              width="200"
              height="40"
            />
            <img
              src="/images/icons/header/logo-full-white.svg"
              class="image-desktop-non-navbar"
              @click="clickMenu('/', 0)"
              alt="logo-full"
              width="200"
              height="40"
            />
          </router-link>
        </div>
        <div class="d-flex align-items-center" style="gap: 20px">
          <ul>
            <li
              class="nav-item"
              v-for="(item, index) in listItems"
              v-bind:key="index"
              @click="clickMenu(item.link, index)"
            >
              <router-link :to="item.link" style="white-space: nowrap"
                >{{ item.name }}
              </router-link>
            </li>
            <li class="nav-item">
              <a
                href="https://blog.tgl-cloud.com/"
                target="_blank"
                style="white-space: nowrap"
                >{{ header.blog }}
              </a>
            </li>
          </ul>
          <router-link
            to="/contact"
            class="btn-contact"
            style="white-space: nowrap"
          >
            {{ header.contact }}
          </router-link>
          <div class="btn-group">
            <button
              type="button"
              class="btn d-flex align-items-center"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              href="#"
              @click="toggleVisibility"
              @keydown.space.exact.prevent="toggleVisibility"
              @keydown.esc.exact="hideDropdown"
              @keydown.shift.tab="hideDropdown"
              @keydown.up.exact.prevent="startArrowKeys"
              @keydown.down.exact.prevent="startArrowKeys"
            >
              <img
                :src="`/images/header/${$i18n.locale}.png`"
                alt="flag"
                width="38"
                height="25"
                class="mr-1"
              />
            </button>
            <div
              class="dropdown-menu"
              v-bind:style="{
                display: isVisible ? 'block' : 'none',
              }"
              aria-labelledby="dropdownMenuLink"
            >
              <p class="px-3 mt-2 font-weight-bold">{{ header.selectLang }}</p>
              <a
                v-for="(item, index) in listLanguage"
                v-bind:key="index"
                :class="`d-flex px-3 py-2 align-items-center mt-1 text-dark item-language ${
                  $i18n.locale === item.locale ? 'active' : 'hover'
                }`"
                href="#"
                @click.prevent="clickLanguage(item.locale)"
                ><img :src="item.image" alt="flag" width="38" height="25" />
                <span class="ml-2">{{ item.title }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hamburger" v-bind:class="{ active: navOpen }">
      <div
        v-bind:style="{
          display: !navOpen ? 'block' : 'none',
        }"
      ></div>
      <button
        id="sidemenu__btn"
        aria-label="Menu"
        class="sidemenu__btn"
        v-on:click="navOpen = !navOpen"
        v-bind:class="{ active: navOpen }"
      >
        <span class="top"></span>
        <span class="mid"></span>
        <span class="bottom"></span>
      </button>
    </div>
    <div
      id="wrapper-mobile"
      v-bind:class="{ active: navOpen }"
      v-bind:style="{ 'z-index': navOpen ? 999 : 0 }"
    >
      <div id="sidemenu">
        <transition name="translateX">
          <nav v-show="navOpen">
            <div
              class="sidemenu__wrapper"
              style="height: 100vh; padding: 36px 0"
            >
              <div class="logo-mobile" @click="clickMenu('/', 0)">
                <router-link class="navbar-brand js-scroll-trigger" to="/">
                  <img
                    src="/images/icons/header/logo-full.svg"
                    class="image-smartphone"
                    alt="logo-mobile"
                  />
                </router-link>
              </div>
              <ul class="sidemenu__list">
                <li
                  class="nav-item-mobile"
                  v-for="(item, index) in listItems"
                  v-bind:key="index"
                  @click="clickMenu(item.link, index)"
                >
                  <router-link :to="item.link">{{ item.name }}</router-link>
                </li>
                <li class="nav-item-mobile">
                  <a href="https://blog.tgl-cloud.com/" target="_blank"
                    >{{ header.blog }}
                  </a>
                </li>
                <li class="nav-item-mobile" @click="clickMenu('/contact', 7)">
                  <!-- <router-link class="btn-contact-mobile" to="/contact">
                    {{ header.contact }}
                  </router-link> -->
                  <router-link
                    to="/contact"
                    class="btn-contact text-white"
                    style="
                      white-space: nowrap;
                      width: fit-content;
                      padding: 8px 20px;
                      font-size: inherit;
                    "
                  >
                    {{ header.contact }}
                  </router-link>
                </li>
                <li class="change-lang">
                  <div class="btn-group">
                    <button
                      type="button"
                      class="btn dropdown-toggle d-flex align-items-center"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      href="#"
                      @click="toggleVisibility"
                      @keydown.space.exact.prevent="toggleVisibility"
                      @keydown.esc.exact="hideDropdown"
                      @keydown.shift.tab="hideDropdown"
                      @keydown.up.exact.prevent="startArrowKeys"
                      @keydown.down.exact.prevent="startArrowKeys"
                    >
                      <img
                        :src="`/images/${$i18n.locale}.png`"
                        alt="flag"
                        width="30"
                        height="30"
                      />
                    </button>
                    <div
                      class="dropdown-menu"
                      v-bind:style="{
                        display: isVisible ? 'block' : 'none',
                      }"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <p class="px-3 mt-2 font-weight-bold">
                        {{ header.selectLang }}
                      </p>
                      <a
                        v-for="(item, index) in listLanguage"
                        v-bind:key="index"
                        :class="`d-flex px-3 py-2 align-items-center mt-1 text-dark item-language ${
                          $i18n.locale === item.locale ? 'active' : 'hover'
                        }`"
                        href="#"
                        @click.prevent="clickLanguage(item.locale)"
                        ><img
                          :src="item.image"
                          alt="flag"
                          width="38"
                          height="25"
                        />
                        <span class="ml-2">{{ item.title }}</span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import { mapState, mapActions } from "vuex";

export default {
  mixins: [clickaway],
  name: "Navbar",
  data() {
    return {
      pathname: this.$route.path.split("/"),
      navOpen: false,
      index: 0,
      showHeader: false,
      ngonngu: this.$t("lang"),
      header: this.$t("header"),
      lang: "",
      isVisible: false,
      listItems: [
        {
          name: this.$t("header").home,
          router: "Home",
          link: "/",
        },
        {
          name: this.$t("header").about_us,
          router: "AboutUs",
          link: "/about-us",
        },
        {
          name: this.$t("header").why_us,
          router: "People",
          link: "/why-us",
        },
        {
          name: this.$t("header").projects,
          router: "Projects",
          link: "/projects",
        },
        // {
        //   name: this.$t("header").product,
        //   router: "Products",
        //   link: "/products",
        // },
        {
          name: this.$t("header").recruiment,
          router: "Recruitment",
          link: "/recruitment",
        },
      ],
      listLanguage: [
        {
          locale: "vn",
          image: "/images/header/vn.png",
          title: this.$t("lang").vietnam,
        },
        {
          locale: "en",
          image: "/images/header/en.png",
          title: this.$t("lang").english,
        },
        {
          locale: "jp",
          image: "/images/header/jp.png",
          title: this.$t("lang").japannese,
        },
      ],
    };
  },
  created() {
    let val = this.$route.path.split("/");
    this.index = this.getIndexMenu(val[1]);
    if (val[1] === "") {
      this.showHeader = false;
    } else {
      this.showHeader = true;
    }
  },
  methods: {
    ...mapActions("page", ["changeShow"]),
    onScroll() {
      if (this.showHeader) {
        document
          ?.getElementById("wrapper-navbar")
          ?.classList.add("show-navbar");
      } else {
        window.onscroll = () => {
          if (!this.showHeader) {
            if (window.pageYOffset >= 100) {
              document
                ?.getElementById("wrapper-navbar")
                ?.classList.add("show-navbar");
              document
                ?.getElementsByClassName("home-mobile")[0]
                ?.classList.add("show-navbar");
            } else {
              document
                ?.getElementById("wrapper-navbar")
                ?.classList.remove("show-navbar");
              document
                ?.getElementsByClassName("home-mobile")[0]
                ?.classList.remove("show-navbar");
            }
          }
        };
      }
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    clickLanguage(language) {
      this.lang = language;
      localStorage.setItem("lang", language);
      this.$store.dispatch("setLang", this.lang);
      this.isVisible = false;
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    startArrowKeys() {
      if (this.isVisible) {
        this.$refs.dropdown.children[0].children[0].focus();
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex = this.focusedIndex + 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus();
    },

    clickMenu(link, idx) {
      // console.log('menu: ',this.$route.path.split("/")[1], link, this.beforePath)
      this.navOpen = false;
      this.index = idx;
      if (this.$route.path.split("/")[1] === "" && window.pageYOffset < 100) {
        this.showHeader = false;
        this.beforePath = link;
        document
          ?.getElementById("wrapper-navbar")
          ?.classList.remove("show-navbar");
      } else {
        this.showHeader = true;
        this.beforePath = link;
        document
          ?.getElementById("wrapper-navbar")
          ?.classList.add("show-navbar");
      }
    },
    showUnderLineMenu(index) {
      let els = document.getElementsByClassName("nav-item");
      let elMobiles = document.getElementsByClassName("nav-item-mobile");
      for (let i = 0; i < els.length; i++) {
        if (i === index) {
          els[i].classList.add("active");
        } else {
          els[i].classList.remove("active");
        }
      }
      for (let i = 0; i < elMobiles.length; i++) {
        if (i === index) {
          elMobiles[i].classList.add("active");
        } else {
          elMobiles[i].classList.remove("active");
        }
      }
    },
    getIndexMenu(value) {
      const valueProject =
        value.indexOf("projects") !== -1
          ? "/projects"
          : value.indexOf("recruitment") !== -1
          ? "/recruitment"
          : value;
      switch (valueProject) {
        case "/members":
          return 1;
        case "/about-us":
          return 1;
        case "/ceo":
          return 1;
        case "/why-us":
          return 2;
        case "/projects":
          return 3;
        // case "/products":
        //   return 4;
        case "/recruitment":
          return 4;
        case "/contact":
          return 999;
        default:
          return 0;
      }
    },
  },
  mounted() {
    this.showUnderLineMenu(
      this.getIndexMenu("/" + this.$route.path.split("/")[1])
    );
    this.onScroll();
    document.addEventListener(
      "mouseup",
      function (e) {
        e.target.className !== "dropdown-menu" && this.hideDropdown();
      }.bind(this)
    );
  },
  watch: {
    lang(val) {
      this.$i18n.locale = val;
      this.header = this.$t("header");
      this.banner = this.$t("home.banner");
      this.ngonngu = this.$t("lang");
      this.listItems = [
        {
          name: this.$t("header").home,
          link: "/",
        },
        {
          name: this.$t("header").about_us,
          link: "/about-us",
        },
        {
          name: this.$t("header").why_us,
          link: "/why-us",
        },
        {
          name: this.$t("header").projects,
          link: "/projects",
        },
        // {
        //   name: this.$t("header").product,
        //   link: "/products",
        // },
        {
          name: this.$t("header").recruiment,
          link: "/recruitment",
        },
      ];
      this.listLanguage = [
        {
          locale: "vn",
          image: "/images/header/vn.png",
          title: this.$t("lang").vietnam,
        },
        {
          locale: "en",
          image: "/images/header/en.png",
          title: this.$t("lang").english,
        },
        {
          locale: "jp",
          image: "/images/header/jp.png",
          title: this.$t("lang").japannese,
        },
      ];
    },
    $route(val) {
      this.pathname = this.$route.path.split("/");
      if (val.path === "/") {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
      this.showUnderLineMenu(this.getIndexMenu(val.path));
      //Trigger fix bug mounted not work after back home page
      window.addEventListener("scroll", this.onScroll());
    },
    // index(val) {
    //     this.showUnderLineMenu(val);
    // }
  },
  computed: {
    ...mapState({}),
  },
};
</script>

<style scoped lang="scss">
.container-navbar {
  position: relative;
  display: flex;
  align-items: center;
}
.dropdown-menu {
  right: 0 !important;
  left: auto !important;
  p {
    font-weight: 600;
  }
}
.dropdown-item {
  display: flex;
  gap: 20px;
  align-items: center;
  &.active {
    color: var(--orange);
    background: none;
  }
}
.btn-contact {
  text-decoration: none;
  border: none;
  border-radius: 100px;
  background: #fc7735;
  padding: 8px 28px;
  color: #fff;
  font-size: 1rem;
  text-align: center;
}

.btn-contact:hover {
  opacity: 0.8;
  transition: 0.3s;
}

#wrapper-navbar {
  position: fixed;
  height: 70px;
  width: 100%;
  z-index: 99999;
  // animation: slideDown 0.8s linear;
  background: transparent;
  box-shadow: none;
  transition: 0.3s ease-in-out;
  top: 10px;
  @keyframes slideDown {
    from {
      margin-top: -200px;
    }
    to {
      margin-top: 0;
    }
  }
  .container-header {
    margin: 0 auto;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    .logo {
      height: 100%;
      display: flex;
      align-items: center;
      img {
        width: 250px;
        height: auto;
      }
      .image-desktop-navbar {
        display: none;
      }
      .image-desktop-non-navbar {
        display: block;
      }
    }
    ul {
      list-style-type: none;
      display: inline-flex;
      align-items: center;
      gap: 0 20px;
      margin: auto 0;
      li {
        font-weight: 500;
        font-size: 18px;
        transition: all 0.3s;
        position: relative;
        cursor: pointer;
        text-align: center;
        a {
          text-decoration: none;
          color: #fff;
        }
        &::after {
          content: "";
          background: #fff;
          width: 0%;
          transition: all 0.3s;
          height: 2px;
          position: absolute;
          bottom: -8px;
          left: 0;
        }
        &:hover::after {
          width: 100%;
        }
        &.active {
          a {
            color: var(--orange) !important;
          }
          &::after {
            background: var(--orange) !important;
            width: 100%;
          }
        }
        &.change-lang {
          &::after {
            width: 0% !important;
          }
          .btn:focus {
            box-shadow: none !important;
          }
          .dropdown-toggle::after {
            color: red;
            font-size: 15px;
          }
        }
      }
      .nav-item.highlight {
        animation: hightlight 3s ease-in-out;
        a {
          animation: hightlight 3s ease-in-out;
        }
      }
      @media (max-width: 1200px) {
        gap: 0 12px;
      }
    }
    .langlocale,
    i {
      color: #fff;
    }
  }
  &.show-navbar {
    background: #fff;
    box-shadow: rgb(204 204 204) 0px 2px 10px;
    width: 100%;
    top: 0;
    .langlocale,
    i {
      color: var(--grey-text);
    }
    .logo {
      .image-desktop-navbar {
        display: block;
      }
      .image-desktop-non-navbar {
        display: none;
      }
    }
    .container-header ul li {
      a {
        color: black;
        text-decoration: none;
      }
      &::after {
        background: black;
      }
    }
  }
}

#wrapper-mobile {
  display: none;
  .btn-contact-mobile {
    width: max-content !important;
    background-color: #002a9c;
    padding: 12px 40px;
    border-radius: 25px;
    color: #fff !important;
    font-size: 24px;
  }
}
.hamburger,
.logo-smartphone {
  display: none;
}

@media (max-width: 1280px) {
  .container-navbar {
    position: fixed;
    top: 0;
    height: 75px;
    background: #fff;
    width: 100%;
    z-index: 99;
    box-shadow: 0px 4px 42px rgb(0 0 0 / 10%);
    &.home-mobile {
      background: transparent;
      box-shadow: none;
      width: 100%;
      display: flex;
      align-items: center;
      .view-scroll {
        display: none;
      }
      .hide-scroll {
        display: block;
      }
      &.show-navbar {
        background: #fff;
        box-shadow: rgb(204 204 204) 0px 2px 10px;
        .view-scroll {
          display: block;
        }
        .hide-scroll {
          display: none;
        }
      }
    }
    .dropdown-menu {
      left: 0 !important;
      right: auto !important;
      /* display: relative; */
    }
  }
  #wrapper-navbar {
    display: none;
  }
  .logo-smartphone {
    display: block;
    position: absolute;
    left: 10px;
    z-index: 99;
    width: 200px;
  }
  .hamburger {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 10px 0;
    background: transparent;
    position: fixed;
    right: 10px;
    top: 5px;
    z-index: 99999;
    .logo-smartphone {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 100px;
    }
    .sidemenu {
      &__btn {
        display: block;
        width: 40px;
        height: 40px;
        float: right;
        background: var(--blue-text);
        border: none;
        border-radius: 50%;
        position: relative;
        z-index: 100;
        appearance: none;
        cursor: pointer;
        outline: none;
        span {
          display: block;
          width: 20px;
          height: 2px;
          margin: auto;
          background: white;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          transition: all 0.4s ease;
          &.top {
            transform: translateY(-6px);
          }
          &.bottom {
            transform: translateY(6px);
          }
        }
        &.active {
          background: transparent;
          span {
            background: #000;
          }
          .top {
            transform: rotate(-45deg);
          }
          .mid {
            transform: translateX(-20px) rotate(360deg);
            opacity: 0;
          }
          .bottom {
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  #wrapper-mobile {
    &.active {
      height: 100vh;
    }
    position: absolute;
    width: 100vw;
    display: block;
    position: fixed;
    #sidemenu {
      height: 100%;
      nav {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 99;
        display: flex;
        float: right;
        justify-content: center;
        align-items: center;
        overflow: auto;
      }
      .sidemenu {
        &__btn {
          display: block;
          width: 50px;
          height: 50px;
          float: right;
          background: #fff;
          border: none;
          position: relative;
          z-index: 100;
          appearance: none;
          cursor: pointer;
          outline: none;
          span {
            display: block;
            width: 25px;
            height: 2px;
            margin: auto;
            background: white;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            transition: all 0.4s ease;

            &.top {
              transform: translateY(-8px);
            }

            &.bottom {
              transform: translateY(8px);
            }
          }
          &.active {
            .top {
              transform: rotate(-45deg);
            }
            .mid {
              transform: translateX(-20px) rotate(360deg);
              opacity: 0;
            }
            .bottom {
              transform: rotate(45deg);
            }
          }
        }

        &__list {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        &__item {
          a {
            text-decoration: none;
            line-height: 1.6em;
            font-size: 1.6em;
            padding: 0.5em;
            display: block;
            color: white;
            transition: 0.4s ease;

            &:hover {
              background: lightgrey;
              color: dimgrey;
            }
          }
        }
      }
      .sidemenu__list {
        width: 80vw;
        .nav-item-mobile {
          font-size: 24px;
          a {
            color: rgb(78, 78, 78);
            text-decoration: none;
            font-weight: 400;
            width: 100%;
            display: block;
          }
          padding: 12px;
        }
        .nav-item-mobile.active a {
          text-decoration-line: underline;
          font-weight: 700;
          color: var(--orange);
        }
      }
      .logo-mobile {
        position: relative;
        margin-bottom: 20px;
        /* z-index: 999;
        top: 20px;
        left: 10%;
        img {
          width: 200px;
        } */
      }
    }

    .translateX-enter {
      transform: translateY(-200px);
      opacity: 0;
    }

    .translateX-enter-active,
    .translateX-leave-active {
      transform-origin: top right 0;
      transition: 0.4s all;
    }

    .translateX-leave-to {
      transform: translateY(-200px);
      opacity: 0;
    }
  }
}
@media (max-width: 600px) {
  #wrapper-mobile #sidemenu .sidemenu__list .nav-item-mobile {
    font-size: 16px;
    padding: 8px;
  }
  #wrapper-mobile .btn-contact-mobile {
    font-size: 20px;
    padding: 8px 20px;
  }
}
@keyframes hightlight {
  0% {
    color: tomato;
    transform: scale(1);
  }
  20% {
    color: tomato;
    transform: scale(1.2);
  }

  40% {
    color: tomato;
    transform: scale(0.9);
  }

  60% {
    color: tomato;
    transform: scale(1.2);
  }

  80% {
    color: tomato;
    transform: scale(0.9);
  }
  100% {
    color: tomato;
    transform: scale(1);
  }
}
</style>
