import Vue from "vue";
import Router from "vue-router";
import VueMeta from "vue-meta";

Vue.use(VueMeta);
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Home",
      component: () =>
        import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    },
    {
      path: "/about-us",
      name: "AboutUs",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/AboutUs.vue"),
    },
    {
      path: "/projects",
      name: "Projects",
      component: () =>
        import(/* webpackChunkName: "projects" */ "../views/Projects.vue"),
    },
    {
      path: "/contact",
      name: "Contact",
      component: () =>
        import(/* webpackChunkName: "contact" */ "../views/Contacts.vue"),
    },
    {
      path: "/projects/:id",
      name: "details",
      component: () =>
        import(
          /* webpackChunkName: "detailproject" */ "../components/ProjectDetail.vue"
        ),
    },
    {
      path: "/products",
      name: "Products",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "../views/Products.vue"),
    },
    {
      path: "/recruitment",
      name: "Recruiment",
      component: () =>
        import(/* webpackChunkName: "recruitment" */ "../views/Recruiment.vue"),
    },
    {
      path: "/recruitment/:id",
      name: "DetailJob",
      component: () =>
        import(
          /* webpackChunkName: "detailproject" */ "../components/Renew/Recruitment/DetailJob.vue"
        ),
    },
    {
      path: "/ceo",
      name: "Ceo",
      component: () => import(/* webpackChunkName: "ceo" */ "../views/Ceo.vue"),
    },
    {
      path: "/why-us",
      name: "People",
      component: () =>
        import(/* webpackChunkName: "people" */ "../views/People.vue"),
    },
    {
      path: "/ai-house",
      name: "DecorationHouse",
      component: () =>
        import(
          /* webpackChunkName: "aihouse" */ "../views/DecorationHouse.vue"
        ),
    },
    {
      path: "/ai-house-color",
      name: "DecorationHouseV1",
      component: () =>
        import(
          /* webpackChunkName: "aihouse" */ "../views/DecorationHouse-v1.vue"
        ),
    },
    {
      path: "/ai-house-fourseason",
      name: "DecorationHouseFourSeason",
      component: () =>
        import(
          /* webpackChunkName: "aihouse4season" */ "../views/DecorationHouseFourSeason.vue"
        ),
    },
    {
      path: "/ai-house-3d-design",
      name: "DecorationHouse3D",
      component: () =>
        import(
          /* webpackChunkName: "aihouse3d" */ "../views/DecorationHouse3D.vue"
        ),
    },
    {
      path: "*",
      name: "NotFound",
      component: () => import("../views/NotFound.vue"),
    },
  ],
});
